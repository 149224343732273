@use 'base/variables' as v;
@use 'base/mixins' as m;

 /* ! GALERIA */
/* !__________________________________________________________________________________________________________________________________________ */

.galeria {
    @include m.mixFlex(flex,center,center,0,column);
    padding: v.$sepSeccion 2rem;

    .galeria_content {
        width: 95%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(22.5rem, 1fr));
        gap: 1.5rem;
        justify-content: space-evenly;
        object-fit: cover;
        margin: 3rem 0;    

        img {
            border-radius: 10px;
        }

        @include m.mixMQ(v.$tablet) {
            grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));            
        };
        
        @include m.mixMQ(v.$movil) {
            grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));            
        };
        @include m.mixMQ(v.$movilSM) {
            grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));            
        };

    }    

}
