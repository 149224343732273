@use 'base/variables' as v;
@use 'base/mixins' as m;

.pagsHeader {
    @include m.mixFlex(flex,0,center,wrap,0);

    @include m.mixMQ(v.$movil) {
        flex-direction: column;
    };

    .phImg {
        width: 45%;

        @include m.mixMQ(v.$movil) {
            width: 70%;
        };

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .phContent {
        width: 50%;
        margin-top: 5rem;

        @include m.mixMQ(v.$movil) {
            width: 90%;
            margin-top: 1rem;
        };
    }    
}

// ! Seccón sobre los diferentes Beneficios que obtienen con nosotros
.secBeneficiosWeb {
    padding-bottom: 0;

    @include m.mixMQ(v.$tablet) {
        padding-bottom: 5rem;
    };

    .pwBeneficios {

        @include m.mixFlex(flex,center,center,0,0);
        width: 100%;
        margin-top: 3rem;

        .beneficios_sec {

            width: 33%;
            height: auto;

            .beneficios_content {

                @include m.mixFlex(flex,center,center,0,0);
                margin: 40px 10px;

                .beneficios_der {
                    text-align: right;
                    width: 82%;

                    @include m.mixMQ(v.$tablet) {
                        margin-right: 1rem;
                    };

                }           
                .beneficios_izq {
                    width: 82%;

                    @include m.mixMQ(v.$tablet) {
                        margin-left: 1rem;
                    };
                }            

                h3 {
                    font-size: 1.8rem;

                    @include m.mixMQ(v.$tablet) {
                        font-size: 1.5rem;
                    };
                }
                
                p {
                    font-size: 1.3rem;
                    color: v.$color1;

                    @include m.mixMQ(v.$tablet) {
                        font-size: 1.2rem;
                    };
                }
                
                .calidad_iconos {
                    margin: 0 15px;
                    transition: transform 0.3s ease-in-out;
                    width: 18%;

                    i {
                        height: 6.5rem;
                        line-height: 6.5rem;
                        width: 6.5rem;
                        text-align: center;
                        font-size: 3rem;
                        color: #fff;
                        border-radius: 50%;
                        background: v.$degradadoRed;

                        filter: grayscale(0);
                        transition: filter 0.3s ease-in;

                        @include m.mixMQ(v.$tablet) {
                            height: 4.5rem;
                            line-height: 4.5rem;
                            width: 4.5rem;
                            font-size: 2.5rem;
                        };
                    }

                    @include m.mixMQ(v.$tablet) {
                        margin: 0 10px;
                    };
                }

                &:hover .calidad_iconos {
                    transform: scale(1.1, 1.1);
                }
                
                &:hover .calidad_iconos i {
                    filter: grayscale(1);
                }           
            
                // .calidad_content
                @include m.mixMQ(v.$tablet) {
                    margin: 15px 10px;
                };
                @include m.mixMQ(v.$movil) {
                    // border-top: v.$borde;
                    padding-top: 10px;
                    margin: 0;
                };
            } 
            
            // .calidadSec
            @include m.mixMQ(v.$tablet) {
                width: 32%;
                margin: 5px 0;
            };
            @include m.mixMQ(v.$movil) {
                width: 90%;
            };
        }

        .beneficios_imagen {

            width: 30%;
            height: 100%;
            overflow: hidden;
            
            img {
                object-fit: cover;
                height: 500px;
                width: auto;
                margin: 0 auto;

                @include m.mixMQ(v.$tablet) {
                    height: 100%;
                };
            }

            @include m.mixMQ(v.$tablet) {
                height: 350px;
            };
            @include m.mixMQ(v.$movil) {
                display: none;
            };
        }

        // .Calidad
        @include m.mixMQ(v.$movil) {
            flex-direction: column;
            margin: 15px 0;
        };
    }
}

.pwDemos {
    @include m.mixFlex(flex,center,center,wrap,0);
    margin-top: 5rem;

    .demoCard {
        @include m.mixBox(23%,325px,false,10px,false,v.$box-shadow);
        @include m.mixFlex(flex,0,0,nowrap,column);
        background-image: v.$degradado2;
        background-blend-mode: lighten;
        margin: 2rem;
    
        @include m.mixMQ(v.$desk) {
            width: 28%;
        };
        @include m.mixMQ(v.$tablet) {
            width: 40%;
        };
        @include m.mixMQ(v.$movil) {
            width: 80%;
            height: 380px;
        };
        @include m.mixMQ(v.$movilSM) {
            width: 90%;
            height: 320px;
        };

        .demoImg {
            width: 100%;
            height: 40%;
            position: relative;

            img {
                width: 100%;
                object-fit: cover;
                position: absolute;
                top: -2rem;
                transform: scale(1.3);
                z-index: 100;
            }
        }

        .demoContent {
            width: 100%;
            height: 48%;
            padding: 5rem 1rem 0 1rem;

            @include m.mixMQ(v.$movil) {
                padding-top: 8rem;
            };

            @include m.mixMQ(v.$movilSM) {
                padding-top: 6rem;                
            };
        }

        .demoFooter {
            width: 100%;
            height: 12%;
            @include m.mixFlex(flex,center,center,0,0);
            background: v.$degradadoRed;
            border-radius: 0 0 10px 10px;

            a { 
                margin: 0;
                padding: 1rem 10rem;
                background: none;
                color: v.$blanco;

                @include m.mixMQ(v.$deskXL) {
                    padding: 1rem 7rem;
                };
            }
        }
    }
}

.portaFooter {
    width: 25%; 
    margin: 2rem auto;
    @include m.mixFlex(flex,center,center,0,column);
}

// Styling
.carruselSoft {
	height: 75px;
	margin: 3rem auto;
	overflow:hidden;
	position: relative;
	width: 75%;
	
	&::before,
	&::after {
		@include m.white-gradient;
		content: "";
		height: 100%;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.car-track {
		animation: scrollCar v.$animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 16);
        height: 100%;
	}
	
	.car-soft {
		height: 100%;
		width: 250px;

        img {
            width: auto;
            height: 90%;
            object-fit: cover;
            margin: 0 auto;
        }
	}
}




// ! Seccón para poner alguna promoción para las páginas web
.secPromoWeb {
    background-color: v.$color1;
    text-align: center;
    text-transform: uppercase;
    color: v.$blanco;
    padding: .5rem 0;

    h4 {
        margin: 0;        

        @include m.mixMQ(v.$movilSM) {
            font-size: 1.3rem;
        };
    }
}

// ! Sección en donde se muestran las diferencias entre rentar y comprar
.secVersus {
    padding-bottom: 1rem;

    .vsImagen {
        width: 40%;
        margin: 2rem auto;

        @include m.mixMQ(v.$desk) {
            width: 60%;
        };
        @include m.mixMQ(v.$tablet) {
            width: 85%;
        };
        @include m.mixMQ(v.$movil) {
            width: 95%;
        };
        
        img {
            width: 100%;
            object-fit: cover;
        }
    }
}

