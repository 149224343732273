@use 'base/variables' as v;
@use 'base/mixins' as m;

@keyframes load {
    from {
        left: -100%;
    }

    to {
        left: 0;
    }
}

#sliderSoft {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    top: 0;

    .slidesSoft {
        width: 400%;
        height: 100%;
        position: relative;

        @include m.mixFlex(flex,0,0,0,row);
        // float: left;
        -webkit-animation: slideBanner 30s infinite;
        -moz-animation: slideBanner 30s infinite;
        animation: slideBanner 30s infinite;

        .slider {

            width: 25%; 
        
            /* Legend */
            .legend {
                content: '';
                position: absolute;
                top: 0;
            }

            /* Contents */
            .content {
                width: 100%;
                height: 100%;
                position: absolute;
                overflow: hidden;

                .content-txt {
                    width: 500px;
                    height: 150px;
                    float: left;
                    position: relative;
                    top: 300px;
                    z-index: 2000;
                    -webkit-animation: content-s 7.5s infinite;
                    -moz-animation: content-s 7.5s infinite;
                    animation: content-s 7.5s infinite;

                    @include m.mixMQ(v.$deskXXL) {
                        top: 60%;
                    };
                    @include m.mixMQ(v.$tablet) {
                        top: 340px;
                    };
                    @include m.mixMQ(v.$movil) {
                        top: 50%;
                        width: 380px;
                    };
                    @include m.mixMQ(v.$movilSM) {
                        width: 325px;
                    };

                    h2 {
                        text-transform: uppercase;
                        // font-size: 24px;
                        color: #fff;
                        text-align: left;
                        margin-left: 30px;
                        padding-bottom: 10px;
                    }

                    p {
                        // font-size: 14px;
                        font-style: italic;
                        color: #fff;
                        text-align: left;
                        margin-left: 30px;
                    }
                                    
                }
                  
            }

            .image {
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                } 
            }            
        }

    }

    .switch {
        width: 120px;
        height: 10px;
        position: absolute;
        bottom: 30px;
        z-index: 3000;
        right: 20px;

        & >ul {
            list-style: none;
        }

        &>ul>li {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: v.$color1;
            float: left;
            margin-right: 5px;
            cursor: pointer;
        }

        ul {
            overflow: hidden;
        }                
    }

}   


.on {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: v.$blanco;
    position: relative;
    -webkit-animation: on 30s infinite;
    -moz-animation: on 30s infinite;
    animation: on 30s infinite;
}

/* Animation */
@-webkit-keyframes slideBanner {

    0%,
    100% {
        margin-left: 0%;
    }

    21% {
        margin-left: 0%;
    }

    25% {
        margin-left: -100%;
    }

    46% {
        margin-left: -100%;
    }

    50% {
        margin-left: -200%;
    }

    71% {
        margin-left: -200%;
    }

    75% {
        margin-left: -300%;
    }

    96% {
        margin-left: -300%;
    }
}

@-moz-keyframes slideBanner {

    0%,
    100% {
        margin-left: 0%;
    }

    21% {
        margin-left: 0%;
    }

    25% {
        margin-left: -100%;
    }

    46% {
        margin-left: -100%;
    }

    50% {
        margin-left: -200%;
    }

    71% {
        margin-left: -200%;
    }

    75% {
        margin-left: -300%;
    }

    96% {
        margin-left: -300%;
    }
}

@keyframes slideBanner {

    0%,
    100% {
        margin-left: 0%;
    }

    21% {
        margin-left: 0%;
    }

    25% {
        margin-left: -100%;
    }

    46% {
        margin-left: -100%;
    }

    50% {
        margin-left: -200%;
    }

    71% {
        margin-left: -200%;
    }

    75% {
        margin-left: -300%;
    }

    96% {
        margin-left: -300%;
    }
}

@-webkit-keyframes content-s {
    0% {
        left: -520px;
    }

    10% {
        left: 0px;
    }

    30% {
        left: 0px;
    }

    40% {
        left: 0px;
    }

    50% {
        left: 0px;
    }

    60% {
        left: 0px;
    }

    70% {
        left: 0;
    }

    80% {
        left: -520px;
    }

    90% {
        left: -520px;
    }

    100% {
        left: -520px;
    }
}

@-moz-keyframes content-s {
    0% {
        left: -520px;
    }

    10% {
        left: 0px;
    }

    30% {
        left: 0px;
    }

    40% {
        left: 0px;
    }

    50% {
        left: 0px;
    }

    60% {
        left: 0px;
    }

    70% {
        left: 0;
    }

    80% {
        left: -520px;
    }

    90% {
        left: -520px;
    }

    100% {
        left: -520px;
    }
}

@keyframes content-s {
    0% {
        left: -520px;
    }

    10% {
        left: 0px;
    }

    15% {
        left: 0px;
    }

    30% {
        left: 0px;
    }

    40% {
        left: 0px;
    }

    50% {
        left: 0px;
    }

    60% {
        left: 0px;
    }

    70% {
        left: 0;
    }

    80% {
        left: -520px;
    }

    90% {
        left: -520px;
    }

    100% {
        left: -520px;
    }
}

@-webkit-keyframes on {

    0%,
    100% {
        margin-left: 0%;
    }

    21% {
        margin-left: 0%;
    }

    25% {
        margin-left: 15px;
    }

    46% {
        margin-left: 15px;
    }

    50% {
        margin-left: 30px;
    }

    71% {
        margin-left: 30px;
    }

    75% {
        margin-left: 45px;
    }

    96% {
        margin-left: 45px;
    }
}

@-moz-keyframes on {

    0%,
    100% {
        margin-left: 0%;
    }

    21% {
        margin-left: 0%;
    }

    25% {
        margin-left: 15px;
    }

    46% {
        margin-left: 15px;
    }

    50% {
        margin-left: 30px;
    }

    71% {
        margin-left: 30px;
    }

    75% {
        margin-left: 45px;
    }

    96% {
        margin-left: 45px;
    }
}

@keyframes on {

    0%,
    100% {
        margin-left: 0%;
    }

    21% {
        margin-left: 0%;
    }

    25% {
        margin-left: 15px;
    }

    46% {
        margin-left: 15px;
    }

    50% {
        margin-left: 30px;
    }

    71% {
        margin-left: 30px;
    }

    75% {
        margin-left: 45px;
    }

    96% {
        margin-left: 45px;
    }
}

