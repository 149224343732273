@use 'base/variables' as v;
@use 'base/mixins' as m;


/* ? SECCIÓN VÍDEO */
/* ?___________________________________________________________________________________________________________________________________________ */

.secVideo {
    position: relative;
    width: 100%;
    min-height: 50vh;

    @include m.mixFlex(flex,flex-start,center,0,0);

    .videoContent {
        position: absolute;
        width: 100%;
        z-index: 10;
        @include m.mixFlex(flex,center,center,0,column);

        // Aquí iba la clase PLAY
    }

    .videoPopup {
        position: fixed;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%) scale(0);
        z-index: 10000;
        width: 100%;
        height: 100%;
        @include m.mixFlex(flex,center,center,0,0);        
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        border-radius: 50%;
        visibility: hidden;
        // opacity: 0;
        transition: 0.5s;
        
        &.active {
            visibility: visible;
            // opacity: 1;

            border-radius: 0;
            transition-delay: 0.5s;
            transform: translate(-50%, -50%) scale(1);    
        
        }
        
        iframe {
            width: 560px;
            height: 315px;

            outline: none;
            border: 3px solid #fff;
            box-shadow: 0 15px 35px rgba(243, 243, 243, 0.25);
        
            @include m.mixMQ(v.$movil) {
                width: 90%;
                height: 275px;
            };

            @include m.mixMQ(v.$movilSM) {
                width: 90%;
                height: 235px;
            };

        }

        video {
            max-width: 1200px;
            outline: none;

            @include m.mixMQ(v.$desk) {
                max-width: 90%;
            };    
        }        

        .close {
            position: absolute;
            top: 30px;  right: 30px;
            cursor: pointer;
            max-width: 32px;
            filter: invert(1);
        }    
        
    }        
}

.videos_informativos_container {
    margin: 3rem auto;
    @include m.mixFlex(flex,space-around,center,wrap,row);
    max-width: 1200px;
    height: 550px;
    // padding: 1rem;
    background-color: v.$color4;
    border-radius: 10px; 
    box-shadow: v.$box-shadow;

    @include m.mixMQ(v.$deskXL) {
        width: 95%;
    };

    @include m.mixMQ(v.$tablet) {
        flex-direction: column; 
        height: 750px;
    };

    @include m.mixMQ(v.$movil) {
        flex-direction: column; 
        max-height: 700px;
    };

    .video_menus {
        width: 28%;
        height: 100%;
        padding: 2rem;
        color: v.$blanco;

        @include m.mixMQ(v.$tablet) {
            width: 100%; 
            height: 35%;
        };
    
        @include m.mixMQ(v.$movil) {
            height: 55%;
        };

        .menu_content {
            margin-top: 1rem!important;
            height: 80%;
        }

        h5 {
            color: red;
            padding-bottom: .5rem;
        }

    }

    .video_portada {
        width: 70%;
        height: 100%;
        border-left: 2px solid red;
        position: relative;


        @include m.mixMQ(v.$tablet) {
            width: 100%; 
            height: 60%;
            margin-top: .5rem;
            border-left: 0;
            border-top: 2px solid red;


        };

        @include m.mixMQ(v.$movil) {
            height: 40%;
        };


        img {            
            // width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }


    }

}