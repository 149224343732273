@use 'base/variables' as v;
@use 'base/mixins' as m;

.secProductos {
    @include m.mixFlex(flex,center,center,0,column);

    .productos {
        @include m.mixFlex(flex,center,0,Wrap,0);
        z-index: 1000;
        width: 80%;
        max-width: 120rem;
        margin-top: 2rem;

        @include m.mixMQ(v.$deskXXL) {
            width: 90%;
        };

        @include m.mixMQ(v.$movil) {
            width: 95%; 
        };

    }

    .prodFooter {
        text-align: center;
 
        @include m.mixMQ(v.$movilSM) {
            width: 80%;
        };
    }

    .listaSoft {
    
        @include m.mixFlex(flex,center,center,wrap,column);

        @include m.mixMQ(v.$movil) {
            padding-top: 3rem;
        };
    
        .listaContent {
            @include m.mixFlex(flex,center,center,wrap,0);
            width: 100%;
            max-width: 1445px;

            @include m.mixMQ(v.$deskXL) {
                width: 90%;
            };
            @include m.mixMQ(v.$tablet) {
                width: 95%;                
            };
            @include m.mixMQ(v.$movil600) {
                justify-content: space-evenly;
            };

            .cardSoft {
                @include m.mixBox(28%,390px,false,6px,false,v.$box-shadow);
                @include m.mixFlex(flex,0,0,nowrap,0);
                padding: 0;
                background-color: v.$blanco;
                margin: 1.5rem;
                max-width: 425px;

                @include m.mixMQ(v.$deskXL) {
                    width: 45%;
                    height: 350px;
                };

                @include m.mixMQ(v.$movil600) {
                    width: 90%;
                    height: 325px;
                };

                @include m.mixMQ(v.$movil) {
                    width: 90%;
                };
                @include m.mixMQ(v.$movilSM) {
                    width: 95%;
                };
        
                .cardSoftImg {
                    width: 40%;
                    height: 100%;
                    @include m.mixFlex(flex,center,center,0,0);
                    padding: 1rem;
                    position: relative;
                    overflow: hidden;
        
                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 5px 0 0 5px;
                        background-image: url('../img/FONDO_ROJO.png'); 
                        background-size: cover;
                        background-position: center center;
                        // z-index: -1;
                    }
        
                    img {
                        position: absolute;
                        width: 85%;
                        object-fit: cover;
                        top: 35%;
                        left: 7%;
                    }
                }
        
                .softContent {
                    width: 60%;
                    height: 100%;
                    overflow: hidden;
        
                    video {
                        width: 100.05%;
                        height: 40%;
                        border-radius: 0 5px 0 0;
                        top: 0;
                        object-fit: cover;
                    }
        
                    .softHead {
                        height: 40%;
                        width: 100%;
        
                        img {
                            width: 100%;
                            object-fit: cover;
                            border-radius: 0 5px 0 0;
        
                        }
                    }
        
                    .titSoft {
                        padding: 1rem;
                        height: 45%;
                        overflow: hidden;
        
                        h4 {
                            font-size: v.$titParrafo - 0.4rem;

                            @include m.mixMQ(v.$deskXL) {
                                font-size: v.$titParrafo - 0.6rem;                                
                            };
                            @include m.mixMQ(v.$tablet) {
                                font-size: v.$titParrafo - 0.7rem;
                            };
                        }

                        p {
                            @include m.mixMQ(v.$tablet) {
                                font-size: v.$titParrafo - .95rem;
                            };
                            @include m.mixMQ(v.$movilSM) {
                                font-size: v.$titParrafo - 1rem;
                            };
                        }
                    }
        
                    .softFooter {
                        height: 10%;
                        @include m.mixFlex(flex,center,center,0,0);

                        a {
                            @include m.mixMQ(v.$movilSM) {
                                font-size: 1rem;
                            };
                        }
                    }
                }
            }
        }
    }

    .softPub {

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 6px ;
            cursor: pointer;
        }
    
    }
    
}


.rotar {
    transform: rotateY(180deg);
}
.rotar360 {
    transform: rotateY(360deg);
}