@use 'base/variables' as v;
@use 'base/mixins' as m;

.formatoHero { 
    width: 100%; 
    height: 100vh;
    min-height: 57rem;
    position: relative;  
    color: #fff;
    overflow: hidden; 
    @include m.mixFlex(flex,center,0,0,column);

    .fondo_hero {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 

        opacity: 0;
        filter: brightness(0%);
        transition: all 1.25s ease-in-out;

        img {
            object-fit: cover;
            height: 100%;
            object-position: 50% 50%;
        }

        .move_right {
            @include m.mixMQ(v.$deskXXL) {
                object-position: 1% 50%;                
            };
            @include m.mixMQ(v.$tablet) {
                object-position: 50% 50%;                
            };
        };

        .move_left {
            @include m.mixMQ(v.$deskXXL) {
                object-position: 100% 50%;                
            };
            @include m.mixMQ(v.$tablet) {
                object-position: 50% 50%;                
            };
        };

    }

    .banner_visible {
        opacity: 1;
        filter: brightness(100%);
    }

}

.formatoHeroProd { 
    width: 100%;  
    height: 100vh;
    min-height: 57rem;
    position: relative;  
    color: #fff;
    overflow: hidden; 
    @include m.mixFlex(flex,center,0,0,column);


}

.clsBefore {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
}


.formatoHero_Container {
    position: absolute;
    color: v.$blanco;
    left: 50%;
    top: 50%;

    width: 90%;
    text-align: center;
    transform: translate(-50%, -50%);

    @include m.mixMQ(v.$tablet) {
        width: 70%;
        text-align: center;
        margin: auto;
    };

    @include m.mixMQ(v.$movil) {
        width: 85%;
    };    

    h1 {
        text-transform: uppercase;    
        margin-bottom: 10px ;
        text-shadow: v.$box-shadow;
        
        span {
            color: v.$color1;
            font-weight: 800; 
            text-shadow: v.$box-shadow2;
        }    
    }

    p {
        font-family: v.$fuente_sec;
        color: v.$blanco;
        font-size: v.$titParrafo + .3rem;
        margin-top: 0; 
        // text-align: center;

        @include m.mixMQ(v.$deskXL) {
            font-size: v.$titParrafo + .2rem;            
        };
        
        @include m.mixMQ(v.$tablet) {
            font-size: v.$titParrafo - .3rem;            
        };
    }

    a {
        width: 150px;
        text-align: center;
    }

    .hero-logo {
        width: auto;
        height: 65px;
        margin-bottom: 2rem;
        overflow: hidden;

        @include m.mixMQ(v.$deskXL) {
            height: 55px;
        };

        @include m.mixMQ(v.$tablet) {
            margin: 0 auto 3rem;
            height: 45px;
        };

        @include m.mixMQ(v.$movil) {
            height: 35px;
        };

        img {
            height: 100%;
            width: auto;
            object-fit: cover;
            margin: auto;
            padding: 0;
        }
    }

}

.formatoHero_Robot {
    position: absolute;
    color: v.$blanco;
    left: 0%;
    top: 50%;

    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    text-align: left;

    @include m.mixFlex(flex,center,center,wrap,row);

    .rbt-hero-titulo {
        width: 46%;
        padding: 3rem;

        h1 {
            margin-bottom: 10px ;
            text-shadow: v.$box-shadow;
            
            span {
                color: v.$color1;
                font-weight: 800; 
                text-shadow: v.$box-shadow2;
            }    
        }
    
        h2 {
            color: v.$color1;
        }
    
        p {
            font-family: v.$fuente_sec;
            color: v.$blanco;
            font-size: v.$titParrafo + .3rem;
            margin-top: 0; 
            // text-align: center;
    
            @include m.mixMQ(v.$deskXL) {
                font-size: v.$titParrafo + .2rem;            
            };
            
            @include m.mixMQ(v.$tablet) {
                font-size: v.$titParrafo - .3rem;            
            }; 
        }
    
        a {
            width: 150px;
            text-align: center;
        }
    
    }

    .youtube-container {
        width: 48%;
    }


}


.top-dist {
    @include m.mixMQ(v.$deskSuper) {
        top: 55%;        
    };
}

// .centra-titulo {
//     transform: translateY(-50%);
// }



// ! ===========================================================================================================


.heroSoftware {

    height: 68vh;    
    flex-direction: row;

    width: 100%;  
    min-height: 57rem;
    position: relative;  
    color: #fff;
    overflow: hidden; 
    @include m.mixFlex(flex,center,0,0,row);

    @include m.mixMQ(v.$tablet) {
        height: 100vh;
        width: 100%;
        flex-direction: column;
    };

    .hero_container {
        width: 50%; 
        background: v.$degradadoRed;
        height: 100%;
        overflow: hidden; 
        @include m.mixFlex(flex,center,center,0,column);

        @include m.mixMQ(v.$tablet) {
            width: 100%;
            height: 40%;
            // top: 60%;
        };

        @include m.mixMQ(v.$movil) {
            height: 50%;
            top: 50%;
        };

        .formatoHero_Productos {
            color: v.$blanco;
            width: 100%;
            height: 100%;
            @include m.mixFlex(flex,center,center,0,column);
        
            @include m.mixMQ(v.$deskXL) {
                width: 75rem;        
            };
        
            @include m.mixMQ(v.$tablet) {
                width: 70%;
                text-align: center;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
            };
        
            @include m.mixMQ(v.$movil) {
                width: 85%;
            };    
        
            h1 {
                width: 85%;
                text-transform: uppercase;    
                margin: 0 auto 1rem ;
                text-shadow: v.$box-shadow;
        
                @include m.mixMQ(v.$desk1024) {
                    width: 70%;
                };
                
                @include m.mixMQ(v.$tablet) {
                    width: 100%;
                };

                span {
                    color: v.$color1;
                    font-weight: 800; 
                    text-shadow: v.$box-shadow2;
                }    
            }
        
            p {
                font-family: v.$fuente_sec;
                color: v.$blanco;
                font-size: v.$titParrafo + .3rem;
                margin-top: 0; 
                // text-align: center;
        
                @include m.mixMQ(v.$deskXL) {
                    font-size: v.$titParrafo + .2rem;            
                };
                
                @include m.mixMQ(v.$tablet) {
                    font-size: v.$titParrafo - .3rem;            
                };
            }
        
            a {
                width: 150px;
                text-align: center;
            }
        
            .hero-logo {
                height: 65px;
                margin-bottom: 2rem;
        
                @include m.mixMQ(v.$deskXL) {
                    height: 55px;
                };
        
                @include m.mixMQ(v.$tablet) {
                    margin: 0 auto 3rem;
                    height: 45px;
                };
        
                @include m.mixMQ(v.$movil) {
                    height: 35px;
                };
        
                img {
                    height: 100%;
                    width: auto;
                    object-fit: cover;
                    margin: 0;
                    padding: 0;
                }
            }
        
        
        }
        

        .heroSoft_mockup {
            height: 40%;
            margin-bottom: 2rem;

            @include m.mixMQ(v.$deskXL) {
                height: 35%;
            };
            @include m.mixMQ(v.$desk) {
                height: 30%;
            };
            @include m.mixMQ(v.$tablet) {
                height: 40%;
            };

            img {
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .sliderHero {
        position: relative;
        width: 50%;
        height: 100%;
        background: v.$negro;

        @include m.mixMQ(v.$tablet) {
            width: 100%;
            height: 60%;
        };

        @include m.mixMQ(v.$movil) {
            height: 50%;
        };

        &::before {
            @extend .clsBefore;
            content: "";
            background: linear-gradient(287deg, rgba(0,0,0,0) 30%, rgba(0,0,0,1) 100%);
            background-size: cover;
            background-position: center center;
            z-index: 1000;
        }     
    }

}

.wrapper { 
    position: absolute;
    bottom: 0;
    @include m.mixFlex(flex,center,0,0,0);
    @include m.mixBox(100%,false,false,false,v.$color4,v.$box-shadow);
    backdrop-filter: blur(50px);

    .dynamic-txts {
        @include m.resetear-lista;
        text-align: center;
        margin-left: 15px;
        line-height: 45px;    
        height: 45px;        
        letter-spacing: 10px;
        overflow: hidden;

        @include m.mixMQ(v.$movil) {
            letter-spacing: 5px;            
        };

        li {
            color: v.$blanco;
            font-size: v.$titParrafo;
            font-weight: 600;
            top: 0;
            position: relative;

            padding: 0 15px;
            animation: slide 18s steps(6) infinite;

            @include m.mixMQ(v.$movil) {
                font-size: v.$titParrafo - 1rem;
            };

            &::after {
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: v.$color4;
                border-left: 2px solid v.$blanco;
                animation: typing 3s steps(24) infinite;
            }
        }
    }
}