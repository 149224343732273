@use 'base/variables' as v;
@use 'base/mixins' as m;


// ! SECCIÓN DE COMISIONES
.disComisiones {

    .dcomPlanComisiones {
        @include m.mixFlex(flex,center,center,wrap,0);
        margin: 3rem auto;

        @include m.mixMQ(v.$movil) {
            flex-direction: column;
            margin-bottom: 0;
        };

        .dcomImagen {
            width: 30%;
            margin-right: 2rem;

            @include m.mixMQ(v.$movil) {
                width: 60%;
            };

            img {
                width: 100%;
                object-fit: cover;
            }

        }

        .dcomContenido {
            width: 65%;
            @include m.mixFlex(flex,center,center,nowrap,column);

            @include m.mixMQ(v.$movil) {
                width: 90%;
                margin-top: 2rem;
            };

            .dcomPlan {
                @include m.mixFlex(flex,false,center,nowrap,row);
                margin: 1rem;
                width: 100%;

                .dcomPlan_porcentaje {
                    width: 100px;
                    margin-right: 1rem;

                    h3 {
                        margin: 0;
                        @include m.mixIcono(8rem, 3rem);        
                        background: v.$degradadoRed;
                        color: v.$blanco; 
                    }

                }

                .dcomPlan_text {
                    width: 90%;

                    h4 {
                        margin-bottom: .5rem;
                    }
                }
            }
        }
    }
}

// ! SECCIÓN DE LAS VERSIONES DEMOS
.disSecDemos {
    @include m.mixFlex(flex,center,center,wrap,column);
    padding-bottom: 0;

    .dsdImagen {
        width: 350px;
        margin: 2rem auto;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .dsdContent {
        @include m.mixFlex(flex,center,center,wrap,column);

        .dsdTextos_parrafo {
            width: 50%;

            @include m.mixMQ(v.$tablet) {
                width: 80%;
            };

            @include m.mixMQ(v.$movil) {
                width: 95%;
            };
        }
        .dsdTextos_imagen {
            width: 60%;

            @include m.mixMQ(v.$tablet) {
                width: 80%;
            };

            @include m.mixMQ(v.$movil) {
                width: 105%;
            };
        }
    }
}

.dis_distribuidores {
    margin-top: 5rem;
    width: 98%;

    .cont_distribuidores {
        margin-top: 5rem;
        @include m.mixFlex(flex,space-evenly,center,wrap,row);

        .distribuidor_card {
            width: 380px;
            box-shadow: v.$box-shadow;
            border-radius: 10px;
            padding: 1rem;
            margin: 1rem;
            overflow: hidden;

            @include m.mixMQ(v.$tablet) {
                width: 46%;
            };

            @include m.mixMQ(v.$movil) {
                max-width: 450px;
                width: 95%;
                height: auto;
            };

            .discard_logo {
                width: 95%;
                height: 150px;
                overflow: hidden;
                margin-bottom: 1.5rem;

                @include m.mixMQ(v.$movil) {
                    height: 125px;
                };

                img {
                    height: 100%;
                    object-fit: contain;
                }
            }

            h6 {
                margin-bottom: 2rem;
                font-size: 1.6rem;
                color: v.$color1;
            }
            
        }
    }
}