@use 'base/variables' as v;
@use 'base/mixins' as m;

/* ? DESTACADOS */
/* ?___________________________________________________________________________________________________________________________________________ */

.secDestacados {

    width: 100%;
    position: relative;

    .destacados { 

        @include m.mixFlex(flex,center,center,wrap,0);
        position: relative;
        z-index: 100;
        width: 80%;
        max-width: 120rem;
        margin: 2rem auto;

        @include m.mixMQ(v.$desk) {
            width: 95%;
        };
        @include m.mixMQ(v.$movil) {
            width: 98%;
        };

        .destacados_card {

            @include m.mixFlex(flex,center,center,0,0);
            position: relative;

            @include m.mixBox(28%,220px,false,15px,false,v.$box-shadow);
            margin: 1.5rem;
            overflow: hidden;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            background: rgba(0,0,0, 0.60);
            max-width: 325px;

            @include m.mixMQ(v.$tablet) {
                width: 29%;
                height: 320px;
            };

            @include m.mixMQ(v.$movil600) {
                width: 45%;
                height: 250px;
                margin: 1rem;
            };
            
            @include m.mixMQ(v.$movil414) {
                width: 95%;
                height: 200px;
            };

        
            .content {
                padding: 20px;
                transition: 0.5s;

                i {
                    position: absolute;
                    top: 10px;
                    right: 30px;
                    font-size: 5em;
                    color: rgba(255, 255, 255, 0.5);
                    pointer-events: none;
                    transition: 0.5s;    
                    z-index: -1;

                    @include m.mixMQ(v.$movil) {
                        font-size: 3.5em;
                    };
                }
                
                h4 {
                    z-index: 1000;
                    margin-bottom: 1.5rem;
                    color: v.$color1;
                    text-shadow: v.$box-shadow2;
                }

                p {
                    color: v.$blanco;
                }                        
            }
        }    
    }

}