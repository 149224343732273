@use 'base/variables' as v;
@use 'base/mixins' as m;



.tit_solucion { 
    width: 75%; 
    text-align: left;   
    margin: 5rem 0 1rem; 
    z-index: 100;

    @include m.mixMQ(v.$deskXXL) { 
        width: 80%;  
    };

    @include m.mixMQ(v.$desk) {
        width: 90%;  
    }; 
}

.secProSol { 
    @include m.mixFlex(flex,center,center,0,row);
    height: 60vh;
    min-height: 575px; 
    overflow: hidden;   
    
    @include m.mixMQ(v.$tablet) {
        flex-direction: column;
        height: auto;
    };

    .secPS_article {
        width: 50%;
        padding: 5rem 3rem; 
        height: 100%;
        border-right: 5px solid v.$color1;
        @include m.mixFlex(flex,center,center,0,column);

        @include m.mixMQ(v.$tablet) {
            width: 100%;
            border-right: none; 
            border-bottom: 5px solid v.$color1;
        };

        @include m.mixMQ(v.$movilSM) {
            padding: 5rem 2rem;
        };

        .lista_problemas {
            width: 90%;
            height: 60%;
            position: relative;
            margin-top: 3rem;
            z-index: 100;
            @include m.mixFlex(flex,0,center,0,0);

            @include m.mixMQ(v.$deskXL) {
                width: 90%;
                height: 320px;
            };
        }

    }

    // ! SOLUCIÓN
    .secImg_fondo {
        width: 50%;
        position: relative;
        padding: 3rem;
        height: 100%;  
        @include m.mixFlex(flex,center,flex-start,nowrap,column);

        @include m.mixMQ(v.$tablet) {
            width: 100%;
        };
        
        .lista_elementos_white {
            margin-left: 0;
        }

    }

}

.funciones_soft {
    overflow: hidden; 
    .formasPago {
        @include m.mixFlex(flex,center,0,0,0);
        margin-top: 1rem;

        // .funciones_drag { 
        //     width: 40px;
        //     margin: 0 auto; 

        //     img {
        //         width: 40px;
        //         animation: dragico 2s infinite;
        //     }
        // }
    
        .fpIndicaciones {    
            width: 90%;
            p small {
                color: v.$negro;
            }
        }
    }
}

.video_software {
    width: 100%;
    height: 45vh;
    min-height: 400px ;
    background: v.$degradadoRed;
    @include m.mixFlex(flex,center,center,wrap,row);
  
    @include m.mixMQ(v.$movil) {
        flex-direction: column;
        height: auto;
    };

    // ! GRUPOS DE CARACTERÍSTICAS CON ICONO CIRCULAR
    .vid_caracteristicas {
        width: 25%;
        @include m.mixFlex(flex,center,0,wrap,column);

        @include m.mixMQ(v.$tablet) {
            width: 20%;
        };

        @include m.mixMQ(v.$movil) {
            flex-direction: row;
            width: 100%;
            margin: 2rem 0;

        };

        .elementoCar {
            text-align: center;
            margin-bottom: 1rem;
            padding: 5px;

            @include m.mixMQ(v.$movil) {
                width: 32%;
            };

            i {
                @include m.mixIcono(7rem, 2rem);
                background: v.$blanco;
                color: v.$color1; 
                margin-bottom: 1rem;
                cursor: auto;

                @include m.mixMQ(v.$tablet) {
                    @include m.mixIcono(5rem, 2rem);                    
                };
            }

            h5 {
                font-size: v.$titH5 - .5rem;
                color: v.$blanco;

                @include m.mixMQ(v.$tablet) {
                    font-size: v.$titH5 - .8rem;                    
                };
            }
        }
    }

    .vs_video {
        width: 40%;
        height: 100%;
        padding: 1rem;

        @include m.mixMQ(v.$deskXL) {
            width: 50%;
        };

        @include m.mixMQ(v.$tablet) {
            width: 60%;
        };

        @include m.mixMQ(v.$movil) {
            width: 100%;
        };

        video {
            width: 100%;
            height: 100%;
            max-height: 100%;
            top: 0;
            object-fit: cover;
            box-shadow: v.$box-shadow2;
            border: 2px solid v.$blanco;
            
        }

    }

}


// ! SECCIÓN LISTA DE PRECIOS
.precios_software {
    position: relative;
    
    .pwPresentador {
        position: absolute;
        width: 300px;
        height: auto;
        top: -13.3rem;
        left: calc(50vw - 125px);
        margin: 0 auto;
    }
    
    .precios_planes {        
        @include m.mixFlex(flex,center,center,wrap,0);
        margin: 3rem auto;
        width: 90%;

        @include m.mixMQ(v.$deskXXL) {
            width: 100%;
        };

    }

    .precios-promocion {
        width: 95%;
        max-width: 1200px;
        box-shadow: v.$box-shadow5;
        margin: 3rem auto;

        border-radius: 10px;

        img {
            border-radius: 10px;
            width: 100%;
            object-fit: cover;
        }
    }

}

// ! SECCIÓN LISTA DE PRECIOS    
.sec-informes {
    width: 100%;
    background: v.$degradadoRed;

    .tituloWhite {
        h3 {
            color: v.$negro;

            span {
                color: v.$blanco;
            }
        }
    }

    .informes_content {
        @include m.mixFlex(flex,center,center,nowrap,column);
        width: 75%;
        margin: 2rem auto 0;

        @include m.mixMQ(v.$movil) {
            width: 90%;
        };

        p {
            color: v.$blanco;
            text-align: center;
        }

        h5 {
            color: v.$blanco;
            margin-top: 2rem;
        }

        .informes_text {
            width: 70%;

            p {
                color: v.$blanco;
            }
        }
    }

    .informes-accion {
        display: flex;
        margin-top: 2rem;
        justify-content: center;
    }

}

// ! SECCIÓN DE PROMOCIONES 
.promociones {
    position: relative;

    .promo_img {
        width: 100%;
        overflow: hidden;

        @include m.mixMQ(v.$movil) {
            height: 245px;
        };
        @include m.mixMQ(v.$movil414) {
            height: 200px;
        };
 
        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .promo_content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include m.mixFlex(flex,center,center,wrap,column);

        .promocion {
            width: 60%;
            height: 100%;
            padding-right: 7rem;
            padding-left: 2rem;
            @include m.mixFlex(flex,center,center,wrap,column);
            text-align: center;

            @include m.mixMQ(v.$movil414) {
                width: 80%;
                padding-right: 3rem;
            };
            
            i {
                color: v.$color1;
                font-size: v.$titHero + 5rem;
                margin-bottom: 2rem;

                @include m.mixMQ(v.$desk) {
                    font-size: v.$titHero + 3rem;
                };

                @include m.mixMQ(v.$tablet) {
                    font-size: v.$titSec;
                    margin-bottom: .5rem;
                };

                @include m.mixMQ(v.$movil) {
                    font-size: v.$titSec - 1rem;
                };

                @include m.mixMQ(v.$movilSM) {
                    font-size: v.$titSec - 1.5rem;                    
                };
            }

            h2 {
                text-transform: uppercase;
                font-weight: bolder;
                font-size: v.$titHero;

                @include m.mixMQ(v.$desk) {
                    font-size: v.$titHero - .3rem;
                };

                @include m.mixMQ(v.$tablet) {
                    font-size: v.$titHero - 2rem;                    
                    margin-bottom: .5rem;
                };

                @include m.mixMQ(v.$movil) {
                    font-size: v.$titHero - 3rem;
                    margin-bottom: 0;
                };

                @include m.mixMQ(v.$movilSM) {
                    font-size: v.$titHero - 3.2rem;
                };

                span {
                    color: v.$color1;
                }
            }

            h4 {
                @include m.mixMQ(v.$movil) {
                    font-size: v.$titParrafo - 1rem;
                };
                @include m.mixMQ(v.$movilSM) {
                    font-size: v.$titParrafo - 1.2rem;
                    margin-bottom: .5rem; 
                };
            }

            a {
                margin-top: 2rem;

                @include m.mixMQ(v.$desk) {
                    margin-top: 1.5rem;
                };

                @include m.mixMQ(v.$tablet) {
                    margin-top: 1rem;
                };

                @include m.mixMQ(v.$movil) {
                    display: none;
                };
            }

            .promo_validoen {
                padding: 1rem 0;
                text-align: left;

                h5 { 
                    text-align: center;
                    margin-bottom: 1rem;

                    @include m.mixMQ(v.$tablet) {
                        font-size: v.$titH5 - .5rem;
                    };

                    @include m.mixMQ(v.$movil) {
                        font-size: v.$titH5 - 1rem;     
                        margin-bottom: .5rem;                   
                    };
                }

                .prElement {
                    @include m.mixFlex(flex,0,center,nowrap,0);
                    width: 100%;
        
                    i {
                        color: v.$color6;
                        font-size: 1.4rem;
                        margin: 0;
                        margin-right: 1rem;

                        @include m.mixMQ(v.$movilSM) {
                            font-size: 1.2rem;                            
                        };
                    }
        
                    p {
                        margin: 2px 0;
                        line-height: 1.25;
                        font-size: 1.4rem;

                        @include m.mixMQ(v.$tablet) {
                            font-size: 1.2rem;
                        };

                        @include m.mixMQ(v.$movilSM) {
                            margin: 0;
                        };

                        span {
                            font-weight: bold;
                        }    
                    }
                }

                strong {
                    display: inline-block;
                    color: v.$color1;
                    margin-top: 1rem;

                    @include m.mixMQ(v.$tablet) {
                        font-size: 1rem;
                    };
                    @include m.mixMQ(v.$movilSM) {
                        margin-top: .5rem;
                    };

                }
            }

            .boton {
                @include m.mixMQ(v.$movilSM) {
                    font-size: 1rem;
                    padding: .5rem 2rem;
                    width: 70%;
                };
            }
        }
    }
}


.soft_titulo {
    width: 85%;
    margin: 0 auto;

    h1 {
        width: 100%;
        text-align: center!important;
        font-size: v.$titHero - 1rem;

        @include m.mixMQ(v.$deskXL) {
            font-size: v.$titHero - 2rem;                    
        };

        @include m.mixMQ(v.$desk) {
            font-size: v.$titHero - 2.2rem;
        };

        @include m.mixMQ(v.$movil414) {
            font-size: v.$titHero - 2.5rem;
        };

        @include m.mixMQ(v.$movilSM) {
            font-size: v.$titHero - 2.8rem;
        };

        span {
            color: v.$negro!important;
            text-shadow: none!important;
        } 
    }

    p {
        width: 80%;
        margin: 0 auto;
        font-size: v.$titParrafo + .5rem;
        text-align: center;

        @include m.mixMQ(v.$deskXL) {
            font-size: v.$titParrafo - .2rem;
        };

        @include m.mixMQ(v.$desk) {
            width: 100%;
            font-size: v.$titParrafo - .5rem!important;
        };

    }

}


