@use 'base/variables' as v;
@use 'base/mixins' as m;


// ! SECCIÓN DE CONTACTO
.secContacto {

    width: 80%;
    margin: 4rem auto;
    @include m.mixFlex(flex,center,0,wrap,0); 

    @include m.mixMQ(v.$deskXXL) {
        width: 95%;
    };

    @include m.mixMQ(v.$tablet) {
        flex-direction: column;
        width: 90%;
    };

    @include m.mixMQ(v.$movil) {
        width: 95%;
    };

    .secDatosContacto {
        width: 40%;
        // height: 100%;
        background: linear-gradient(0deg, rgba(141,0,18,1) 35%, rgba(203,0,26,1) 100%);
        padding: 2rem;

        @include m.mixFlex(flex,center,center,wrap,column);
        color: v.$blanco;

        @include m.mixMQ(v.$tablet) {
            width: 100%;
            padding: 3rem;
        };

        .sdcImagen {
            @include m.mixBox(150px,150px,false,50%,v.$blanco,v.$box-shadow);
            border: 5px solid v.$blanco;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        h4 {
            text-transform: uppercase;
            margin-top: 2rem;
        }

    }

    .secFormularioContacto {
        width: 60%;
        height: 100%;
        overflow: hidden;
        position: relative;
        color: v.$blanco;
        padding: 2rem;
        
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../img/EdificioBDK.jpg');
            background-size: cover;
            background-position: center center;    
            z-index: -2;
        }

        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.6);
            // backdrop-filter: blur(10px);
            // -webkit-backdrop-filter: blur(10px);
            z-index: -1;
        }

        @include m.mixMQ(v.$tablet) {
            width: 100%;
        };


        .formulario {

            input::placeholder, label {
                font-size: 1.3rem;
            }

            select {
                font-size: 1.3rem;

                option, option[selected] {
                    font-size: 1.3rem;
                }
            }

            p {
                color: v.$blanco;
            }
            
        }

        .fch_nota {
            background-color: v.$color5;
            padding: 1.2rem;            
            margin: 1rem;
            border-radius: 5px;

            p {
                font-size: 1.3rem;
            }

            small {
                color: v.$color2;
                font-size: 1.2rem;
            }
        }

        textarea { 
            padding: 1rem;

            &::placeholder {
                font-size: 1.3rem;
            }
        }
    }
}

.sdc_info {
    margin: 1rem auto;
    width: 90%;

    h5 {
        font-size: 1.6rem;
        margin-bottom: .5rem;
        text-align: center;
    }

    p {
        color: v.$blanco;
        margin: .5rem 2rem;
    }

    small {
        margin-left: 4rem;
    }

    .botonesWhats {
        @include m.mixFlex(flex,center,center,wrap,false);
        text-align: center;

        small {
            font-size: 1rem;
            margin: 0;
        }

        .botonVerde {
            border-radius: 5px;
            padding: 1.2rem;
            margin: .5rem;
            font-size: 1.2rem;

            i, a, small {
                display: block;
            }

            i {
                font-size: 1.5rem;
            }
        }
        
    }
}
