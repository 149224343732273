@use 'base/variables' as v;
@use 'base/mixins' as m;

.secPaginasWeb {

    width: 100%;
    height: 65vh;
    position: relative;

    @include m.mixMQ(v.$movil) {
        height: auto; 
    };

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/FondoSecPagWeb.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

        @include m.mixMQ(v.$movil) {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/FondoSecPagWeb_Movil.jpg');
        }
    }

    h3, p {
        color: v.$blanco;
    }

    small {
        color: v.$color6;
        margin: 1rem 0;
    }

    .spwContent {
        height: 90%;
        overflow: hidden;
        @include m.mixFlex(flex,center,center,wrap,0);

        @include m.mixMQ(v.$movil) {
            flex-direction: column;
            height: auto;
        };

        .spwPortada {
            width: 50%; 
            height: 100%;
            padding: 2rem;
            @include m.mixFlex(flex,center,center,0,0);

            @include m.mixMQ(v.$tablet) {
                padding: 1rem;                
            };

            @include m.mixMQ(v.$movil) {
                width: 80%;
                height: auto;
            };

            img {
                width: 100%; 
                object-fit: cover;

            }
        }

        .spwTextos {
            width: 50%;
            @include m.mixFlex(flex,center,0,0,column);

            @include m.mixMQ(v.$movil) {
                width: 100%;
                height: auto;
            };

            h3 {
                text-transform: uppercase;
            }
            a {
                width: 200px;
                text-align: center;
            }
        }
    }
 
}