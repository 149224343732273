@use 'base/variables' as v;
@use 'base/mixins' as m;


.secBienvenida {
    padding-bottom: 3rem;
    position: relative;

    .sec_videos {
        width: 100%;
        margin: 2rem auto;
        @include m.mixFlex(flex,center,center,wrap,column);
    
        @include m.mixMQ(v.$tablet) {
            width: 90%;
        };
    }
    
    .bienvenida-box {
        margin: 3rem auto; 

        .bienvenida-mensaje {
            margin: 1rem auto;
            width: 48%;

            @include m.mixMQ(v.$tablet) {
                width: 90%;
            };

            .msj-container {

                margin-top: -2rem;
                margin-bottom: 2rem;

                @include m.mixFlex(flex,left,center,wrap,row);

                @include m.mixMQ(v.$tablet) {
                    margin-top: -5rem;                    
                };

                .msj-contacto {
                    width: 35%;

                    @include m.mixMQ(v.$movil) {
                        width: 90%;
                    };
                }

            }

            .msj-content {

                width: 100%;
                padding: 2rem;
                background: v.$degradadoRed;
                border-radius: 80px;
                box-shadow: v.$box-shadow;
                color: v.$blanco;
    
                @include m.mixFlex(flex,center,center,nowrap,row);

                @include m.mixMQ(v.$desk) {
                    border-radius: 15px;
                };

                @include m.mixMQ(v.$movil) {
                    flex-direction: column;
                };

                .clsQuote {
                    font-size: 2rem;
                    color: v.$color1;
                    padding: 2rem;
                    background: v.$blanco;
                    border-radius: 50%;
                    box-shadow: v.$box-shadow4;
                    margin-right: 2rem;
                }
    
                p {
                    color: v.$blanco;
                    font-size: 1.8rem;
                    text-align: left;
                }
    
            } 

        }
    }
    
}
