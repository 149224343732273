@use 'variables' as v;
@use 'mixins' as m; 

@import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;600;700&family=Roboto:wght@300;400;700;900&display=swap');

html { 
    font-size: 62.5%; 
    box-sizing: border-box; 
    scroll-padding-top: 0rem; 
    scroll-behavior: smooth;  
}  
 
*,    
*:before,      
*:after {   
    box-sizing: inherit;     
    margin: 0;      
} 
 
body {  
    font-family: v.$fuente_principal;  
    font-size: 16px; 

    &::-webkit-scrollbar {  
        width: 8px;
        height: 8px; 
    }

    &::-webkit-scrollbar-thumb {
        background: v.$color1; 
        border-radius: 5px;  
    } 
}  

.body-noscroll {
    overflow-y: hidden;
    overflow-x: hidden; 
}

p {
    font-size: v.$descCon;      
    color: v.$negro; 
    line-height: 1.20;    
    margin: .25rem 0; 
    font-family: v.$fuente_sec;   
} 

.container {
    width: 90%;
    max-width: 120rem; 
    margin: 0 auto;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4 {
    margin: 0 0 1rem 0;
    font-family: v.$fuente_principal;
}

h1 {
    font-size: v.$titHero;  

    @include m.mixMQ(v.$deskXXL) {
        font-size: v.$titHero - 1.5rem;                    
    };

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titHero - 2rem;                    
    };

    @include m.mixMQ(v.$movil414) {
        font-size: v.$titHero - 2.5rem;
    };

    @include m.mixMQ(v.$movilSM) {
        font-size: v.$titHero - 2.8rem;
    };

}

h2 {
    font-size: v.$titSec;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titSec - 1rem;
    }        

    @include m.mixMQ(v.$movil) {
        font-size: v.$titSec - 1.3rem;        
    };

}

h3 {
    font-size: v.$titulos;
}
h4 {
    font-size: v.$titParrafo;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titParrafo - .3rem;        
    };

}

h5 {
    font-size: v.$titH5;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titH5 - .3rem;        
    };

    @include m.mixMQ(v.$movil) {
        font-size: v.$titH5 - .6rem;        
    };
}

img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block; 
}

.iconos {
    @include m.mixIcono(4rem, 1.7rem);
    background: v.$degradadoRed;
    color: v.$blanco; 

    transition: transform .3s ease-in;
    
    &:hover {
        transform: rotate(360deg);
    }    
}

.icono_peq {
    @extend .iconos;

    @include m.mixIcono(2rem, 1.2rem);
    margin-right: .5rem;
}

.iconos-blanco {
    @extend .iconos;

    background: v.$blanco;
    color: v.$color2;
}

.iconos-verde {
    @extend .iconos;

    background: v.$degradado4;
    color: v.$blanco; 
}

.seccion {
    padding: v.$sepSeccion 0;
} 

.seccionGris {
    @extend .seccion;
    background: #C9CCD3;
    background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten;
}

.seccionGrisOscuro {
    @extend .seccion;
    background: v.$color4;
}

// Titulo de cada sección 
.titulo {
    margin: 0 auto;
    text-align: center;
    width: 85%;

    .mockup {
        width: 300px;
        margin: 0 auto 3rem;

        @include m.mixMQ(v.$movil) {
            width: 250px;
        };
    }

    h2 {
        text-transform: uppercase;

        span { 
            font-weight: 900;
            color: v.$color1;
            text-shadow: v.$box-shadow2;
        }
    } 

    p {
        font-size: v.$descSec;
        font-weight: bold;

        @include m.mixMQ(v.$tablet) {
            font-size: v.$descSec - .1rem;
        };
        @include m.mixMQ(v.$movil) {
            font-size: v.$descSec - .3rem;
        };

    }

    small {
        color: v.$color1;
        font-weight: bold;
        margin: 1.5rem 0;
        display: block;
    }

    .linea {
        width: 100px;
        border: 1px solid v.$color4;
        margin: 0 auto;
        animation: parpadeo 1.5s linear .5s infinite; 
    }
}

.tituloWhite {
    @extend .titulo;

    h2, p {
        color: v.$blanco;

        span {
            color: v.$color1;
        }
    }
}

.txtWhite {
    color: v.$blanco;
}

.txtM2 {
    margin-top: 2rem!important;
}

.txtM5 {
    margin-top: 5rem;
}

.bor10 {
    border-radius: 10px;
}

.bor10sup {
    border-radius: 10px 10px 0 0;
}

.boton {
    margin: 1rem;
    display: inline-block;
    font-size: v.$descCon;
    color: v.$blanco;
    border-radius: 50px;
    cursor: pointer;
    padding: .8rem 3rem;
    background: v.$color1;
    transition: all 0.4s ease-in;
    border: 2px solid v.$blanco;

    &:hover {
        color: v.$blanco;
        box-shadow: inset -10em 0 0 0 v.$color4, inset 10em 0 0 0 v.$color4;
        border: 2px solid v.$color1;
    }
    
    &:active {
        transform: scale(0.98);
    }    

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$descCon - 0.2rem;  
    };
}

.botonVerde {
    @extend .boton;
    background: v.$color6; 
    border: 2px solid v.$blanco;

    &:hover { 
        color: v.$color6;
        border: 2px solid v.$color6; 
    }

}

// Clase del botón que moverse al inicio de la pantalla
.botInicio {
    position: fixed;
    left: 150px;
    bottom: 32px;
    z-index: 10000; 
}
 
.widgetWP {
    position: fixed;
    left: -30px; 
    bottom: 20px;
    z-index: 10000;  
}

#widgetEnVivo {
    position: fixed;
    right: 20px; 
    top: 15px;
    z-index: 10000;
    padding: .7rem 5px;
    background: v.$degradadoRed;
    border-radius: 5px;
    transition: all 0.3s ease-in;


    &:hover {
        // box-shadow: inset -10em 0 0 0 v.$color4, inset 10em 0 0 0 v.$color4;
        background: v.$color4;
        border: 2px solid v.$color1;
    }

    a {
        font-size: 1.2rem;
        color: v.$blanco;
        padding: 2px 5px; 
    }
}


#enVivo {

    i { 
        margin-right: 2px;
    }

}

.onRecording {
    -webkit-animation: colorRecord 4s linear 1s infinite;
    animation: colorRecord 4s linear 1s infinite; 
}

.bannerPub {
    padding: 0;
    width: 80%;
    object-fit: cover;
    overflow: hidden;
    margin: 0 auto;

}

.tit-enorme {    
    font-size: 100px;

    @include m.mixMQ(v.$desk) {
        font-size: 80px;        
    };

    @include m.mixMQ(v.$tablet) {
        font-size: 60px;
    };
    @include m.mixMQ(v.$movilSM) {
        font-size: 45px;
    };
}

.font-bold {
    font-weight: bold;
}

.text-derecha {
    text-align: right;
}

.text-izq {
    text-align: left!important;
}

.anim-mouse {
    width: 100%;
    position: absolute;
    bottom: 6rem;
    display: flex;
    justify-content: center;
    padding-right: 3rem;

    .mouse {
        @include m.bgGradient;
        position: relative;
        width: v.$widthMouse;
        height: v.$heightMouse;
        border-radius: 100px;
        background-size: 100% 200%;
        animation: 
        colorSlide v.$animDuration linear infinite,
        nudgeMouse v.$animDuration ease-out infinite;

        @include m.mixMQ(v.$tablet) {
            width: v.$widthMouseSM;
            height: v.$heightMouseSM;                
        };
        
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
        }
        &:before {
            width: v.$widthMouse - v.$borderMouse;
            height: v.$heightMouse - v.$borderMouse;
            background-color: v.$colorBg;
            border-radius: 100px;

            @include m.mixMQ(v.$tablet) {
                width: v.$widthMouseSM - v.$borderMouseSM;
                height: v.$heightMouseSM - v.$borderMouseSM;                    
            };
        }
        &:after {
            background-color: v.$colorOutline;
            width: v.$sizeTrackball; 
            height: v.$sizeTrackball;
            border-radius: 100%;
            animation: trackBallSlide v.$animDuration linear infinite;
        }
    }
}

.mouse-b2 {
    bottom: 2rem;
}

.formasPago {    
    margin: 2rem auto 0;
    width: 95%;
    @include m.mixFlex(flex,center,center,wrap,0);
    text-align: center;

    @include m.mixMQ(v.$desk) {
        width: 97%;
    };

    @include m.mixMQ(v.$tablet) {
        flex-direction: column;
    };
    
    .fpIndicaciones {
        width: 50%;
        padding: 2rem;
        @include m.mixFlex(flex,center,center,wrap,column);

        @include m.mixMQ(v.$desk) {
            width: 95%;
        };

        @include m.mixMQ(v.$movil) {
            width: 98%;
            padding-top: 0;
        };

        p {

            color: v.$blanco;
            font-weight: bold;
            margin-bottom: 1rem;
        
            small {
                color: v.$blanco;
                margin: 0 .3rem;
                font-size: 1.4rem;

                @include m.mixMQ(v.$tablet) {
                    font-size: 1.5rem;
                };

                @include m.mixMQ(v.$movil) {
                    font-size: 1.2rem;
                }; 

                .fa-caret-right {
                    font-size: 1.6rem;
                    color: v.$color6;
                }

                .fa-exclamation-triangle {
                    color: v.$color1;
                }
            }
        }
    }

    .fpTiposTar {

        width: 50%;
        @include m.mixFlex(flex,center,center,wrap,row);

        @include m.mixMQ(v.$desk) {
            width: 62%;
        };

        @include m.mixMQ(v.$tablet) {
            width: 90%;
        };

        .fpTarjetas {
            color: v.$blanco;
            width: 48%;

            @include m.mixMQ(v.$tablet) {
                width: 45%;
            };
            @include m.mixMQ(v.$movil) {
                width: 90%;
                margin-bottom: 2rem;
            };

            h4 {
                margin: 0;
            }

            .imgTarjetas {
                width: 200px;
                margin: 0 auto;
            }
        }    

        .fpMSI {
            color: v.$blanco;
            width: 48%;

            @include m.mixMQ(v.$tablet) {
                width: 45%;
            };
            @include m.mixMQ(v.$movil) {
                width: 90%;
            };

            h4 {
                margin-bottom: 0;
            }

            small {
                display: block;
                margin-top: .5rem;
            }
        }
    }
}

// ! SECCIÓN DE PREGUNTAS FRECUENTES
.genFaqs {
    padding-top: 1rem; 
    padding-bottom: 1rem;

    .disFaqs_container {  
        margin: 2rem auto;  
        padding: 2rem;  

        @include m.mixMQ(v.$movilSM) {
            padding: 2rem 0;
        };
    } 

    .accordion {

        .accordion-item {
            border-bottom: 1px solid v.$color5;

            button[aria-expanded='true'] {
                border-bottom: 1px solid v.$color1;
            }
        }

        button {
            position: relative; 
            display: block;
            text-align: left;
            width: 100%;
            padding: 2em 0;
            font-size: 1.5rem;
            font-weight: 400;
            border: none;
            background: none;
            outline: none;
            font-family: v.$fuente_principal;

            @include m.mixMQ(v.$movil) {
                font-size: 1.3rem;
            };

            &:hover,
            &:focus {
                cursor: pointer;
                color: v.$color1;

                &::after {
                    cursor: pointer;
                    color: v.$color1;
                    border: 1px solid v.$color1;
                }
            }

            .accordion-title {
                padding: 1em 1.5em 1em 0;
            }

            .iconFaq {
                display: inline-block;
                position: absolute;
                top: 18px;
                right: 0;
                width: 22px;
                height: 22px;
                border: 1px solid;
                border-radius: 22px;
                color: v.$color3;

                &::before {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 9px;
                    left: 5px;
                    width: 10px;
                    height: 2px;
                    background: currentColor;
                }

                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 5px;
                    left: 9px;
                    width: 2px;
                    height: 10px;
                    background: currentColor;
                }
            }
        }

        button[aria-expanded='true'] {
            color: v.$color1;

            .iconFaq {
                &::after {
                    width: 0;
                }
            }

            +.accordion-content {
                opacity: 1;
                max-height: 9em;
                transition: all 200ms linear;
                will-change: opacity, max-height;

                @include m.mixMQ(v.$movilSM) {
                    max-height: 12em;
                };
            }
        }

        .accordion-content {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 200ms linear, max-height 200ms linear;
            will-change: opacity, max-height;

            p {
                font-size: 1.5rem;
                font-weight: 300;
                margin: 2em 0;

                @include m.mixMQ(v.$movil) {
                    font-size: 1.2rem;
                };
            }
        }
    }
}

// ! GRUPOS DE CARACTERÍSTICAS CON ICONO CIRCULAR
.gpoCaracteristicas {
    margin: 4rem auto;
    @include m.mixFlex(flex,center,0,wrap,0);
    margin-bottom: 1rem;

    .elementoCar {
        width: 30%;
        text-align: center;
        margin-bottom: 2rem;
        padding: 10px;

        @include m.mixMQ(v.$tablet) {
            width: 33%;
        };

        @include m.mixMQ(v.$movil) {
            margin-bottom: 0;
        };

        @include m.mixMQ(v.$movil414) {
            width: 45%;
        };

        i {
            @include m.mixIcono(8rem, 3rem);
            background: v.$degradadoRed;
            color: v.$blanco; 
            margin-bottom: 1rem;
            cursor: auto;
        
            @include m.mixMQ(v.$movilSM) {
                @include m.mixIcono(6rem, 2rem);
            };
        }

        h5 {
            font-weight: bold;

            @include m.mixMQ(v.$movilSM) {
                font-size: v.$titH5 - 1rem;
            };
        }

        p {

            @include m.mixMQ(v.$tablet) {
                font-size: 1.3rem;
            };

            @include m.mixMQ(v.$movil) {
                display: none;
            };
        }
    }
}


// ! CLASE PARA DAR FORMATO A UN DIV QUE CONTENGA UNA FOTO Y UNA LISTA
.divFoto_lista {
    @include m.mixFlex(flex,center,center,nowrap,0);
    margin: 3rem 3rem 0;

    @include m.mixMQ(v.$movil) {
        flex-direction: column;
        align-items: baseline;
    };
    
    .elImagen {
        width: 300px;
        height: 250px; 
        margin: auto 0;        

        @include m.mixMQ(v.$movil) {
            margin: auto; 
            width: 85%;
        };

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .divFoto_detalle {
        margin-left: 3rem;
    }
    
}

.lista_elementos {
    width: 100%;
    height: auto;
    margin: 1.5rem 0;
    z-index: 500;
    overflow: hidden;    

    .lista_elemento {
        @include m.mixFlex(flex,0,center,nowrap,0);
        margin: 10px 0;

        i {
            font-size: 1.5rem;
            margin-top: 2px;
            margin-right: .8rem;
        }
    
        .color_ver {
            color: v.$color6;
        }

        .color_blanco {
            color: v.$blanco;
        }
        p {
            margin: 0;
            line-height: 1.20;
            font-size: 1.5rem;
            text-align: left!important;
            width: 100%;
        }

        a {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &:link {
                color: v.$blanco;
            }
              
            // &:visited {
            //     color: #0a07b5;
            // }            
        }

    }

    small {
        display: block;
        margin-top: 2rem;
        color: v.$blanco;
    }

}

.color_rojo {
    color: v.$color1;
}

.lista_elementos_white {
    @extend .lista_elementos;

    margin: 2rem;

    p {
        color: v.$blanco;
    }
}


.regresar {
    width: 200px;
    margin: 0 auto;
}

.filtro-negro { 
    position: absolute;
    width: 100%;
    height: 100%; 
    z-index: -5;
    top: 0;
    left: 0;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        content: "";
        background: linear-gradient(270deg, rgba(0,0,0,.1) 20%, rgba(0,0,0,1) 100%);        

        @include m.mixMQ(v.$tablet) {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);            
        };
    }

    img {
        object-fit: cover;
        height: 100%;
    }
}

.filtro-negro-robot {
    @extend .filtro-negro;

    &::before { 
        background: linear-gradient(270deg, rgba(0,0,0,.1) 30%, rgba(0,0,0,1) 100%);        

        @include m.mixMQ(v.$tablet) {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0) 55%);
        };

        @include m.mixMQ(v.$movil) {
            background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 85%);
        };

    }    
}

.filtro-sinfondo {
    @extend .filtro-negro;

    &::before {
        background: none;        
    }    
}

.filtro-negro-border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px 10px 0 0;
    }    
}


.filtro-negro-deg2 {
    @extend .filtro-negro;

    &::before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
    }    
}

.filtro-negro-deg2border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
    }    
}

 
.planes_paquete {
    @include m.mixBox(10%,680px,0,10px,v.$blanco,v.$box-shadow4);
    margin: 1rem;
    padding: 2rem;
    position: relative; 

    .paquete_nombrePlan { 
        @include m.mixBox(100%,auto,0,10px,v.$color5,false);
        padding: 1rem 0; 
        text-align: center;  
        color: v.$color1; 

        h3 {
            margin-bottom: 0;
        }

        .paquete_nombre_tipo {
            @include m.mixFlex(flex,center,center,nowrap,row);
            width: 95%;
            margin: 0 auto;

            .paq_nom_tipoplan {
                @include m.mixBox(95%,auto,0,10px,v.$blanco,v.$box-shadow4);
                padding: 1rem;
                margin: 1rem;

                h5 {
                    font-size: 1.3rem; 
                    color: v.$negro;
                    margin-bottom: .5rem;
                }
                h3 {
                    font-size: 2rem;
                    color: v.$color2;

                    span {
                        font-size: 1.2rem;
                        color: v.$color2;
                    }
                }
                small {
                    font-size: 1.2rem;
                    color: v.$color2;
                }
            }
        }
    }

    .oro {
        background: v.$degradadoRed;
        color: v.$blanco;
    }

    .paquete_contenidoPlan {
        padding: 2rem 0;
        height: auto;
        overflow: hidden;

        @include m.mixMQ(v.$desk) {
            height: 72%; 
        };

        .paqContenido_header {
            text-align: center;
            margin: 0 auto;

            @include m.mixMQ(v.$movil) {
                width: 90%;
                margin-top: 2rem;
            };

            h5 {
                font-size: 1.2rem;
                width: 85%;
                margin: 0 auto;
            }
            h4 {
                font-weight: normal;

                @include m.mixMQ(v.$tablet) {
                    margin-bottom: .5rem;
                };

            }
            h3 {
                font-size: v.$titParrafo;
                color: v.$color1;

                @include m.mixMQ(v.$tablet) {
                    font-size: v.$titParrafo - .5rem; 
                    margin-bottom: .3rem;                           
                };
            }
        }

    }    

    .paquete_footer {
        width: 220px;
        text-align: center;
        height: 10%;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);

        @include m.mixMQ(v.$tablet) {
            margin: 0;
            height: auto;
        };
    }
}

.planes_paquete_2col {
    @extend .planes_paquete;

    width: 35%;
 
    @include m.mixMQ(v.$desk) {
        width: 42%;
    };

    @include m.mixMQ(v.$tablet) {
        width: 45%;
        height: 630px;
    };

    @include m.mixMQ(v.$movil600) {
        width: 90%;
        height: auto
    };

}

.planes_paquete_3col {
    @extend .planes_paquete;

    width: 30%;

    @include m.mixMQ(v.$tablet) {
        width: 40%;
    };

    @include m.mixMQ(v.$movil600) {
        width: 90%;
        height: auto;
    };
}

.h650 {

    @include m.mixMQ(v.$desk) {
        height: 720px;
    };

    @include m.mixMQ(v.$movil) {
        height: auto;
    };

}

.h700 {

    @include m.mixMQ(v.$deskXL) {
        height: 700px;
    };

    @include m.mixMQ(v.$desk) {
        height: 800px;
    };

    @include m.mixMQ(v.$movil600) {
        height: auto;
    };

}    

.sliderbar_container {
    width: 90%;
    margin: 3rem auto 0;
    padding: 1rem; 
    overflow: hidden;
    @include m.mixFlex(flex,0,0,nowrap,0);
    position: relative;

    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        width: 8px;     /* Tamaño del scroll en vertical */
        height: 8px;    /* Tamaño del scroll en horizontal */
        /* display: none;  Ocultar scroll */
    }
    
    /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
    &::-webkit-scrollbar-thumb {
        background: v.$color1;
        border-radius: 4px;
    }

    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    &::-webkit-scrollbar-thumb:hover {
        // background: #b3b3b3;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    /* Cambiamos el fondo cuando esté en active */
    &::-webkit-scrollbar-thumb:active {
        background: v.$color1;
    }
    
    &::-webkit-scrollbar-track {
        /* background: #e1e1e1; */
        border-radius: 4px;
    }

    /* Cambiamos el fondo cuando esté en active o hover */
    &::-webkit-scrollbar-track:hover,
    &::-webkit-scrollbar-track:active {
        background: #d4d4d4;
    }        

    .mode-screen {
        flex-wrap: wrap;
        scroll-snap-type: y mandatory;
        background-color: #fff;
        height: 80%;
    }
}

.card_giratoria {
    @include m.mixBox(350px,460px,0,10px,0,0);
    flex-shrink: 0;
    transform-style: preserve-3d;
    perspective: 500px;
    margin: 2rem 1rem;
    z-index: 2000;
    

    @include m.mixMQ(v.$tablet) {
        width: 45%;
        height: 480px;
    };

    @include m.mixMQ(v.$movil600) {
        height: 450px;
    };

    @include m.mixMQ(v.$movil) {
        width: 90%;
    };
    
    .front {
        transform-style: preserve-3d;
        transition: 1s;
        backface-visibility: hidden;
        width: 100%;
        height: 100%;
       
        .card_producto--frente {
            @include m.mixFlex(flex,center,0,0,0);
            height: 100%;
            width: 100%;

            .fondoCard {
                width: 100%;
                height: 100%;
                top: 0;

                img {
                    border-radius: 10px;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .cardprod_content {
                position: absolute;
                top: 0;
                width: 80%;
                height: 100%;
                overflow: hidden;
                @include m.mixFlex(flex,center,center,0,column);

                img {
                    width: 100%;
                    object-fit: cover;
                }

                i {
                    position: absolute;
                    bottom: 5rem;
                    color: v.$blanco;
                    font-size: 2.5rem;
                    cursor: pointer;
                    transition: .5s;
                    border-radius: 50%;

                    @include m.mixMQ(v.$movil) {
                        bottom: 3rem;
                    };
                                
                    &:active {
                        transform: scale(0.95);
                    }

                    &:hover {
                        color: v.$color1;
                    }
                }
                
            }
        }

        .card_funciones--frente {
            width: 100%;
            height: 100%;
            background-color: v.$color4;
            border-radius: 15px;
            box-shadow: v.$box-shadow;

            .funcion--header {
                position: relative;
                height: 43%;
                z-index: 100;
                
                .funciones_titulo {
                    position: absolute;
                    color: v.$blanco;                    
                    top: 50%;
                    left: 2rem;
                    transform: translateY(-50%);
                    text-transform: uppercase;
                    text-align: center;
                    width: 90%;
                }
            }

            .funcion--main {
                height: 57%;
                border-top: solid 3px v.$color1;
                padding: 2rem;
                position: relative;

                p {
                    color: v.$blanco;
                }

            }
        }
    }

    .back {
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: 1s;
        backface-visibility: hidden;
        position: absolute;
        top: 0;

        .card_producto--reverso {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -10;
            
            .card_BH {
                height: 30%;
            
                img {
                    border-radius: 10px 10px 0 0;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .cardprod_descrip {
                position: absolute;
                top: 35%;
                background: linear-gradient(0deg, rgba(141,0,18,1) 28%, rgba(205,0,26,1) 83%, rgba(205,0,26,0) 100%);
                width: 100%;
                height: 60%;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 1rem 2rem;

                @include m.mixMQ(v.$desk) {
                    top: 32%;
                    height: 62%;
                };

                @include m.mixMQ(v.$movil) {
                    top: 40%;                        
                    height: 55%;
                };

                .titSoft {
                    height: 80%;
                    margin-top: 3rem;
                    overflow: hidden;

                    @include m.mixMQ(v.$desk) {
                        margin-top: .8rem;                            
                    };
                    @include m.mixMQ(v.$movil) {
                        margin-top: 0;
                    };

                    h4 {
                        color: v.$blanco;
                        margin: 2.5rem 0 0.8rem 0;
                    }

                    p {
                        color: v.$blanco;
                        margin: 0;
                    }
                }
            }

            .cardprod_botones {
                position: absolute;
                bottom: 0;
                height: 10%;
                width: 100%;
                border-radius: 0 0 10px 10px;
                @include m.mixFlex(flex,space-between,center,0,0);
                background-color: v.$negro;
                padding: 0 2.5rem;
                z-index: 100;

                i {
                    display: block;
                    color: v.$blanco;
                    font-size: 2rem;
                    cursor: pointer;
                    padding: 8px 8px;
                
                    &:active {
                        transform: scale(0.95);
                    }    

                    &:hover {
                        color: v.$color1;
                    }

                }
            }
        }

        // ! Reverso de las card para mostrar las diferentes funciones de los módulos de los sistemas
        .card_funciones--reverso {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -10;
            background-image: v.$degradadoRed;
            border-radius: 10px;
            box-shadow: v.$box-shadow;

            .funcion--caracteristicas {
                width: 100%;
                height: 90%;
                padding: 1.5rem;

                .lista_elemento {
                    p {
                        color: v.$blanco;
                    }
                }
            }
        }
    }
}

.opCard {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    color: v.$blanco;
    font-size: 2.5rem;
    cursor: pointer;
    transition: .5s;
    border-radius: 50%;
    transform: translateX(-50%);
    padding: 1rem;

    @include m.mixMQ(v.$tablet) {
        bottom: 1rem;
    };
                
    &:active {
        transform: scale(0.95);
    }

    &:hover {
        color: v.$color1;
    }
}

.animacion_drag {
    width: 40px;
    margin: 0 auto;

    img {
        width: 40px;
        animation: dragico 2s infinite;
    }
}

.promo_buenfin {
    margin-top: 3rem;
    @include m.mixFlex(flex,left,center,nowrap,row);

    @include m.mixMQ(v.$tablet) {
        justify-content: center;
        flex-direction: column;
    };

    .logo_buenfin {
        width: 200px;
        height: 200px;

        @include m.mixMQ(v.$movil600) {
            width: 100px;
            height: 100px;
        };
    }

    .texto_buenfin {
        margin-left: 2rem;
        text-align: left;
        
        h3 {
            width: 80%;
            // text-align: left;

            @include m.mixMQ(v.$tablet) {
                width: 100%;
            };

            @include m.mixMQ(v.$movil) {
                font-size: 2rem;
            };
        }
    }
}


.video_presentacion {
    margin-top: 3rem;

    @include m.mixFlex(flex,center,center,nowrap,column);

    .video_container {
        margin-top: 1rem;
        margin-bottom: 3rem;
        @include m.mixBox(840px,472px,false,false,false,v.$box-shadow);

        @include m.mixMQ(v.$tablet) {
            width: 560px;
            height: 315px;
        };

        @include m.mixMQ(v.$movil) {
            width: 420px;
            height: 236px;
            margin-bottom: 5rem;
            text-align: center;
        };

        @include m.mixMQ(v.$movil414) {
            width: 357px;
            height: 200px;
        };

        @include m.mixMQ(v.$movilSM) {
            width: 357px;
            height: 200px;
        };

        @include m.mixMQ(v.$movil360) {
            width: 322px;
            height: 180px;
        };

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }

        p {
            margin-top: 1rem;
        }
    }

}

.testimonios_1x1 {
    margin-top: 3rem;

    @include m.mixFlex(flex,center,center,nowrap,column);

    @include m.mixMQ(v.$tablet) {
        width: 97%!important;
    };

}

.conjunto_testimonios {

    width: 90%;
    @include m.grid(3,2);

    @include m.mixMQ(v.$tablet) {
        width: 95%;        
    };
    @include m.mixMQ(v.$movil) {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);        
    };
    
}

.video_testimonios {

    width: 90%;
    margin: 0 auto;
    @include m.grid(5,2);

    @include m.mixMQ(v.$deskXL) {
        grid-template-columns: repeat(4, 1fr);        
    };

    @include m.mixMQ(v.$tablet) {
        grid-template-columns: repeat(3, 1fr);        
    };

    @include m.mixMQ(v.$movil) {
        grid-template-columns: repeat(2, 1fr);        
    };

}

.portada-testimonio {
    position: relative!important;
    margin: 2rem auto;
    @include m.mixBox(200px,200px,false,false,false,v.$box-shadow);
    border-radius: 10px;

    img {
        border-radius: 10px;
        object-fit: cover;
    }

    @include m.mixMQ(v.$movil600) {
        width: 165px;
        height: 165px;
    };
}

.portada-video {
    position: relative!important;
    margin: 2rem auto;
    @include m.mixBox(850px,475px,false,false,false,v.$box-shadow);
    border-radius: 10px;

    @include m.mixMQ(v.$tablet) {
        width: 90%;
        height: auto;
    };

    img {
        border-radius: 10px;
        object-fit: cover;
    }

}

.botones_descarga {
    @include m.mixFlex(flex,center,center,wrap,0);
}

.pasos_aseguir {
    width: 100%;
    padding: .5rem 2rem;
    margin-bottom: 3rem;
    // border: 1px solid v.$color5;
    border-radius: 10px;
    box-shadow: v.$box-shadow;
}

.marco_video {
    // border: 1px solid v.$color5;
    // border-top: 1px solid v.$color5;
    border-bottom: 1px solid v.$color5;
    padding: .5rem;
    margin: 0!important;
}

.aplica_scroller {

    overflow: hidden;        
    scroll-snap-type: y mandatory;
    overflow-y: scroll;  
    overflow-x: hidden;  

    &::-webkit-scrollbar {
        width: 8px;     /* Tamaño del scroll en vertical */
        height: 8px;    /* Tamaño del scroll en horizontal */
        /* display: none;  Ocultar scroll */
    }
    
    /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
    &::-webkit-scrollbar-thumb {
        background: v.$color1;
        border-radius: 4px;
    }

    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    &::-webkit-scrollbar-thumb:hover {
        // background: #b3b3b3;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    /* Cambiamos el fondo cuando esté en active */
    &::-webkit-scrollbar-thumb:active {
        background: v.$color1;
    }
    
    &::-webkit-scrollbar-track {
        /* background: #e1e1e1; */
        border-radius: 4px;
    }

    /* Cambiamos el fondo cuando esté en active o hover */
    &::-webkit-scrollbar-track:hover,
    &::-webkit-scrollbar-track:active {
        background: #d4d4d4; 
    }        

    
}

.parpadeo { 
    animation: colorRecord 3s linear infinite;     
}

.logo_aniversario {
    position: absolute; 
    width: 180px;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);

    @include m.mixMQ(v.$movil) {
        width: 100px;
        top: 6rem;
        // left: auto;
        // right: 2rem;
    };

    img {
        object-fit: cover;
        width: 100%;        
    }
    
}

.areadepruebas {
    @include m.mixFlex(flex,center,center,0,0);
    width: 50%;
    height: 50vh;
    background: v.$color5;
    border: 10px solid #000;
    margin: auto;

    .botonsistema {
        width: 60px;
        height: 60px;
        margin: 4rem;
        @include m.mixFlex(flex,center,center,0,0);

        i {
            font-size: 3rem;
            color: #fff;
        }
    }

    .bothab {
        background-color: v.$color1;
    }

    .botdes {
        background-color: v.$color3;
    }

}

.dark-mode {
    background-color: v.$color2;
    color: #fff; /* Color de texto claro */

    p, .modal-title p {
        color: v.$blanco;
    }

    .modal-header {
        button {
            color: v.$blanco!important;
        }
    }

}

.modal-body {
    padding: 0!important;
}

.play {
    position: absolute;
    cursor: pointer;
    width: 70px!important;
    height: 70px!important;
    display: block;  
    transition: .5s;
    border-radius: 50%;

    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%);

    -webkit-animation: ripple 2.5s linear 1s infinite;
    animation: ripple 2.5s linear 1s infinite;

    img {
        width: 100%;
        transition: width 0.5s;
    }
    
    &:hover {
        background: v.$color1;
    }            
}        

.paquetes_botones {
    width: 90%;
    @include m.mixFlex(flex,center,center,wrap,row);
}

.promofija {
    background-color: v.$color1;
    margin: 2rem auto 0;
    padding: 1.5rem 3rem;
    border-radius: 10px;
    box-shadow: v.$box-shadow4;

    h5 {
        color: v.$blanco;
        font-weight: bolder;
    }
}

.secBannerPub { 
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 6rem; 

    @include m.mixMQ(v.$deskXL) {
        width: 90%;
    };

    @include m.mixMQ(v.$movil) {
        width: 95%;
    };
}

.fto-img-pub {
    height: 100%;
    object-fit: cover; 
    border-radius: 10px;
    // filter: brightness(0.5);    
}

// .estamos-enlinea {
//     margin: 5rem auto 2rem;
//     width: 90%;
//     max-width: 120rem;
//     box-shadow: v.$box-shadow2;
//     border-radius: 10px;
//     display: none;

//     a {
//         img {
//             border-radius: 10px;
//             width: 100%;
//             object-fit: cover;
//         }

//     }    
// }

.estamos-enlinea {
    width: 100%;
    padding: 3rem;
    background: v.$degradadoRed;
    text-align: center;
    color: v.$blanco;

    .onair-img {
        width: 20%;
        margin: 2rem auto;
        padding: 2rem;
        border-radius: 20px;
        box-shadow: v.$box-shadow4;
        overflow: hidden;
        background-color: v.$negro;

        @include m.mixMQ(v.$tablet) {
            width: 50%;
        };

        img {
            object-fit: cover;
        }
    }

    .onair-text {
        width: 75%;
        margin: 2rem auto;

        @include m.mixMQ(v.$tablet) {
            width: 95%;
        };

        p {
            color: v.$blanco;
        }

        a {
            margin: 1rem auto;
            width: 20rem;
            display: block;
        }

        .barra {
            width: 50%;
            height: 1px;
            background: v.$blanco;
            box-shadow: v.$box-shadow4;
            margin: 2rem auto;
        }

        .fpIndicaciones {
            padding: 1rem;
            @include m.mixFlex(flex,center,center,wrap,column);
        
            p {
    
                color: v.$blanco;
                font-weight: bold;
                margin-bottom: 1rem;
            
                small {
                    color: v.$blanco;
                    margin: 0 .3rem;
                    font-size: 1.8rem;

                    @include m.mixMQ(v.$movil) {
                        font-size: 1.2rem;
                    };
        
                    .fa-caret-right {
                        font-size: 1.6rem;
                        color: v.$color6;
                    }
    
                    .fa-exclamation-triangle {
                        color: v.$color1;
                    }
                }
            }
        }

        .video_envivo {
            position: relative;
            overflow: hidden;
            margin: 3rem auto;
            width: 100%;
            border-radius: 15px;
            box-shadow: v.$box-shadow;

            &::before {
                content: "";
                display: block;
                padding-top: 56.25%; /* Ratio de 16:9 */
            }            

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 15px;

            }
        }
    
    }
}