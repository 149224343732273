@use 'base/variables' as v;
@use 'base/mixins' as m;


.sec_videos_robot {
    width: 95%;
    margin: 3rem auto;

    @include m.mixFlex(flex,space-between,center,wrap,row);

    @include m.mixMQ(v.$tablet) {
        // flex-direction: column;
        margin-top: 0;
    };

    .youtube-container {
        position: relative;
        width: 48%;
        padding-bottom: 27.12%; /* 16:9 Aspect Ratio */ 

        @include m.mixMQ(v.$tablet) {
            width: 95%;
            padding-bottom: 54.24%; /* 16:9 Aspect Ratio */
            margin: 0 auto 4rem;
        };

        .youtube-video {
            position: absolute;            
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;

            iframe {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                box-shadow: v.$box-shadow;
            }
        
        }        

        .youtube-portada {
            position: absolute;            
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;

            img {
                position: absolute;
                width: 100%;
                border-radius: 10px;
                object-fit: cover;
                box-shadow: v.$box-shadow;
            }
        
        }        

    }    
}

.seccion-robodescrip {

    height: 100vh;
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);

    .descrip-rbt-container {
     
        // border: 2px solid v.$color1;        

        width: 80%;
        height: auto;
        // margin: auto;
        position: relative;
        @include m.grid(2,2rem);

        z-index: 100;

        .descrip_rbt_card {

            @include m.mixFlex(flex,center,center,0,0);
            position: relative;

            margin: 3rem;
            overflow: hidden;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            background: rgba(0,0,0, 0.60);
            border-radius: 10px;

            .content {
                padding: 3rem;
                transition: 0.5s;

                i {
                    position: absolute;
                    top: 10px;
                    right: 30px;
                    font-size: 5em;
                    color: v.$color3;
                    pointer-events: none;
                    transition: 0.5s;    
                    z-index: -1;

                    @include m.mixMQ(v.$movil) {
                        font-size: 3.5em;
                    };
                }
                
                h4 {
                    z-index: 1000;
                    margin-bottom: 1.5rem;
                    color: v.$blanco;
                    text-shadow: v.$box-shadow2;
                    font-weight: bold;
                }

                p {
                    color: v.$blanco;
                }

                audio {
                    margin-top: 2.5rem;
                }
            }
        }            

    }
}

.seccion-problema {

    width: 100%;
    height: 90vh;
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);

    @include m.mixMQ(v.$movil) {
        height: auto; 
    };

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/mesero_estresado.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

        @include m.mixMQ(v.$movil) {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/mesero_estresado_Movil.jpg');
        }
    }

    .problema-container {

        max-width: 1200px;
        margin: auto;
        text-align: center;
        color: v.$blanco;

        h2 {
            font-weight: bolder;
            font-size: 6rem;
            
        }

        .pain-points {
            display: flex;
            justify-content: space-around;
            margin: 5rem;            

            .pain-point {

                overflow: hidden;
                border-top: 1px solid rgba(255, 255, 255, 0.5);
                border-left: 1px solid rgba(255, 255, 255, 0.5);
                background: rgba(255,0,0, 0.5);
                border-radius: 10px;
                padding: 3rem;
    
                i {
                    font-size: 5rem;
                    color: v.$blanco;
                    margin-bottom: 1rem;
                }

                p {
                    color: v.$blanco;
                    font-weight: bold;
                    font-size: 2rem;
                }
            }

        }    

        .statistics {
            // font-size: 2.5rem;
            text-align: center;
            color: v.$blanco;
            margin-top: 2rem;
            font-weight: bolder;
        }    

        .rhetorical-questions {
            font-style: italic;
            text-align: center;
            margin-top: 20px;
            color: v.$blanco;
            font-size: 2.2rem;
        }    

    }
}

.seccion-solucion {

    width: 100%;
    height: 90vh; 
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);

    .solution-container {

        width: 75%;
        height: 75%;
        padding: 3rem;
        text-align: center;

        // border-top: 1px solid rgba(255, 255, 255, 0.5);
        // border-left: 1px solid rgba(255, 255, 255, 0.5);
        // background: rgba(0,0,0, 0.60);
        // border-radius: 10px;


        .solution-title {
            font-size: 4rem;
            font-weight: bold!important;
        }        

        .solution-points {
            display: flex;
            justify-content: space-around;
            margin: 4rem;

            .sol-punto {
                width: 18rem;
                height: 18rem;
                background-color: v.$color1;
                padding: 1rem;
                border-radius: 50%;
                @include m.mixFlex(flex,center,center,wrap,false);

                p {
                    width: 100%;
                    font-size: 2rem;
                    // text-align: center;
                    color: v.$blanco;
                    font-weight: bold;
                }
            }
        }        

        .solution-description {
            margin-top: 20px;
            font-size: 18px;
        } 
        
        .solution-cta {
            display: inline-block;
            background-color: #007BFF; /* Puedes cambiar el color */
            color: white;
            padding: 10px 20px;
            margin-top: 20px;
            text-decoration: none;
        }        
    }
}

.seccion-beneficios-robot {

    width: 100%;
    height: 90vh;
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);

    @include m.mixMQ(v.$movil) {
        height: auto; 
    };

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%), url('../img/robot_beneficios.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

        @include m.mixMQ(v.$movil) {
            background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%), url('../img/robot_beneficios_Movil.jpg');
        }
    }

    .beneficios-container {

        max-width: 1200px;
        margin: auto;
        text-align: center;
        color: v.$blanco;

        h2 {
            font-weight: bolder;
            font-size: 6rem;
            
        }

        .beneficios-points {
            margin: 5rem;            

            @include m.grid(4,3rem);

            .beneficios-point {

                overflow: hidden;
                border-top: 1px solid rgba(255, 255, 255, 0.75);
                border-left: 1px solid rgba(255, 255, 255, 0.75);
                background: rgba(0,0,0, 0.30);
                border-radius: 10px;
                padding: 3rem;
                box-shadow: v.$box-shadow4;
    
                i {
                    font-size: 5rem;
                    color: v.$color1;
                    margin-bottom: 1rem;
                }

                p {
                    color: v.$blanco;
                    font-weight: bold;
                    font-size: 2rem;
                }
            }

        }    

        .statistics {
            // font-size: 2.5rem;
            text-align: center;
            color: v.$blanco;
            margin-top: 2rem;
            font-weight: bolder;
        }    

        .rhetorical-questions {
            font-style: italic;
            text-align: center;
            margin-top: 20px;
            color: v.$blanco;
            font-size: 2.2rem;
        }    

    }
}

.seccion-rbt-proceso {

    height: auto;
    position: relative;
    @include m.mixFlex(flex,center,center,false,column);

    .proceso-rbt-container {
     
        width: 90%;
        max-width: 1200px;
        height: auto;
        position: relative;
        z-index: 100;
        margin-top: 3rem;

        @include m.grid(2,3rem);

        .proceso_rbt_card {

            @include m.mixFlex(flex,center,center,0,0);
            position: relative;

            // margin: 3rem;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            background: rgba(0,0,0, 0.75);
            border-radius: 10px;
            width: 100%;

            .content {
                padding: 3rem;
                transition: 0.5s;
                text-align: left;
                width: 100%;

                .card-titulo {

                    @include m.mixFlex(flex,left,center,nowrap,row);
                    margin-bottom: 1rem;

                    .titulo-icono {
                        background-color: v.$blanco;
                        border-radius: 50%;
                        // border: 3px solid v.$blanco;
                        width: 5rem;
                        height: 5rem;
                        @include m.mixFlex(flex,center,center,false,false);

                        i {
                            font-size: 2.6rem;
                            color: v.$color1;
                            pointer-events: none;
                            transition: 0.5s;    
                        }
                            
                    }

                    h4 {
                        z-index: 1000;
                        margin-left: 1rem;
                        margin-bottom: 0;
                        color: v.$blanco;
                        text-shadow: v.$box-shadow2;
                        font-weight: bold;
                    }    
    
                }

                

                p {
                    color: v.$blanco;
                }

                audio {
                    margin-top: 2.5rem;
                }
            }
        }
    }

}

.seccion-precio-robot {

    width: 100%;
    height: auto;
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);

    @include m.mixMQ(v.$movil) {
        height: auto; 
    };

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%), url('../img/robot_precio.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

        @include m.mixMQ(v.$movil) {
            background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%), url('../img/robot_precio_Movil.jpg');
        }
    }

    .rbt-container {

        max-width: 1200px;
        margin: auto;
        color: v.$blanco;

        h2 {
            font-weight: bolder;
            font-size: 4.5rem;
            
        }

        .rbt-card-ctnr {


            width: 100%;
            margin-top: 3rem;
            overflow: hidden;
            @include m.mixFlex(flex,left,center,nowrap,row);

            .rbt-card-img {

                width: 35%;

                overflow: hidden;
                border-top: 1px solid rgba(255, 255, 255, 0.75);
                border-left: 1px solid rgba(255, 255, 255, 0.75);
                background: rgba(255,255,255, 0.75);
                border-radius: 10px;
                // padding: 3rem;

                img {

                    width: 100%;
                    object-fit: cover;
                        
                }

                .rbt-msj {
                    padding: 2rem;
                }

            }

            .rbt-card-detalles {

                width: 65%;
                padding: 3rem;

                @include m.mixFlex(flex,left,center,nowrap,column);
    
                .rbt-modelo {

                    width: 100%;

                    h5 {
                        font-weight: bold;

                        span {
                            font-weight: 400;
                        }

                        small {
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: v.$color1;
                        }
                    }

                    .precio-lista {
                        font-weight: bold;
                        font-style: italic;
                        text-decoration: line-through red;
                        
                    }

                }

                .rbt-caracteristicas {

                    width: 100%;
                    margin-top: 1.5rem;

                    .lista_elementos_white {
                        margin-top: .5rem!important;
                    }

                    p {
                        color: v.$blanco;
                    }

                }

                .rbt-precio {

                    width: 100%;
                    margin-top: 1.5rem;

                    h3 {
                        font-weight: bold;

                        span {
                            font-size: 1.5rem;
                            color: v.$color1;
                        }
                    }

                    .lista_elementos_white {
                        margin-top: .5rem!important;
                    }

                }

            }


        }


    }
}

.seccion-rbt-negocios {

    height: auto;
    position: relative;
    @include m.mixFlex(flex,center,center,false,column);

    .negocios-rbt-container {
     
        width: 90%;
        height: auto;
        margin-top: 3rem;

        @include m.mixFlex(flex,space-around,center,wrap,row);

        .negocios-card {

            width: 300px;
            margin: 15px;
            border: 1px solid #ccc;
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            box-shadow: v.$box-shadow;

            img {
                width: 100%;
                height: auto;
            }
              
            .negocios-card-content {

                padding: 1.5rem;
                background: v.$blanco;

            }
        }
    }

}


.seccion-rbt-despedida {

    width: 100%;
    height: 80vh;
    position: relative;

}


.youtube-container {
    position: relative;
    width: 48%;
    padding-bottom: 27.12%; /* 16:9 Aspect Ratio */ 

    @include m.mixMQ(v.$tablet) {
        width: 95%;
        padding-bottom: 54.24%; /* 16:9 Aspect Ratio */
        margin: 0 auto 4rem;
    };

    .youtube-video {
        position: absolute;            
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            box-shadow: v.$box-shadow;
        }
    
    }        

    .youtube-portada {
        position: absolute;            
        top: 0;
        left: 0;
        width: 100%; 
        height: 100%;
        background-size: cover;

        img {
            position: absolute;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
            box-shadow: v.$box-shadow;
        }
    
    }        

}    
