@use 'base/variables' as v;
@use 'base/mixins' as m;

.secEquipo {

    .eqContent {
        width: 90%;
        margin: 3rem auto;
        @include m.mixFlex(flex,0,center,0,column);

        img {
            width: 350px;
            object-fit: cover;

            @include m.mixMQ(v.$desk) {
                width: 280px;
            };
        }

        @include m.mixMQ(v.$movil) {
            width: 98%;
        };

        .eqTextos {
            width: 60%;
            text-align: center;
            margin: 3rem 0;

            @include m.mixMQ(v.$tablet) {
                width: 90%;
            };

            .eqLista {
                margin-top: 1rem;
            }

            p small {
                color: v.$color1;
                margin: 0 .2rem;
            }

        }

        .hardware {
            @include m.mixFlex(flex,center,center,wrap,0);
            
            @include m.mixMQ(v.$desk) {
                width: 60%;
            };
            @include m.mixMQ(v.$tablet) {
                width: 95%;
            };

            .element {
                @include m.mixBox(100px,100px,0,6px,v.$color1,v.$box-shadow3);
                margin: 1rem;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .paquetes_botones {
        width: 300px;
        margin: 3rem auto 0;
        @include m.mixFlex(flex,center,0,0,0);
    }
      
}

.lista_equipo {

    width: 100%;
    margin-top: 4rem;
    @include m.mixFlex(flex,center,center,wrap,row);

    .box {
        @include m.mixFlex(flex,center,0,0,column);
        border-radius: 1rem;
        box-shadow: v.$box-shadow;
        width: 30%;
        height: 480px;
        margin: 1.5rem 1rem;

        @include m.mixMQ(v.$tablet) {
            width: 45%;
        };
        @include m.mixMQ(v.$movil) {
            width: 100%;
            height: 165px;
            flex-direction: row;
        };

        .box_image {
            width: 100%;
            height: 45%;
            overflow: hidden;
            position: relative;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
                transition: all .5s ease-in-out;

                @include m.mixMQ(v.$movil) {
                    border-top-right-radius: 0;
                };
            }
            
            //.box_image
            @include m.mixMQ(v.$movil) {
                height: 100%;
                width: 35%;
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
                border-top-right-radius: 0;
            };
        }            

        .box_content {
            padding: 1rem 1rem 0 1rem;
            @include m.mixFlex(flex,0,0,0,column);
            height: 50%;

            //.box_content
            @include m.mixMQ(v.$movil) {
                height: 100%;
                width: 65%;
                padding: 1.2rem 1.2rem .8rem 1.2rem;
            };

            .box_texto {
                height: 70%;
                overflow-y: auto;

                a {

                    h5 {
                        transition: all 0.5s ease-in-out;

                        &:hover {
                            color: v.$color1;
                        }
                    }
                }

                // DESCRIPCIÓN DEL PRODUCTO
                p {
                    font-size: 1.6rem;
                    color: v.$color4;

                    @include m.mixMQ(v.$movil) {
                        font-size: 1.4rem;
                    };                                
                }
            }

            .box_footer {
                @include m.mixFlex(flex,space-between,center,0,0);
                height: 30%;
                border-top: v.$borde;
                padding: 5px;

                i {
                    @include m.mixIcono(3rem, 1.5rem);
                    color: v.$color4;
                    border: v.$borde;
                    margin-left: 0;

                    &:hover {
                        background: v.$color1;
                        color: v.$blanco;
                        border: none;
                    } 

                }

                span {
                    display: inline-block;
                    color: v.$color1;
                    font-size: 2rem;
                    font-weight: 800;
                    text-align: right;

                    @include m.mixMQ(v.$movil) {
                        font-size: 1.5rem;
                    };
                }

                // .box_footer
                @include m.mixMQ(v.$movil) {
                    border-top: none;
                };


            }
            
        }   

        &:hover .box_image img {

            transform: scale(1.05);
            -webkit-transform: scale(1.05);

            filter: saturate(180%);
        }
        
    }             

}

.box_image_modal {
    width: 100%!important;
    height: 350px!important;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    @include m.mixMQ(v.$movil) {
        height: 180px!important;
    };

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        transition: all .5s ease-in-out;

        @include m.mixMQ(v.$movil) {
            border-top-right-radius: 0;
        };
    }
    
    //.box_image
    @include m.mixMQ(v.$movil) {
        height: 100%;
        width: 35%;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0;
    };
}            
