@use 'base/variables' as v;
@use 'base/mixins' as m;

.banners_fade {
    margin: 10rem auto 1rem;        
    max-width: 900px;
    padding: 2rem;
    height: 500px;
    position: relative;

    @include m.mixMQ(v.$tablet) {
        max-width: 700px;
        height: 400px;
    };

    @include m.mixMQ(v.$movil600) {
        width: 90%;
        height: 305px;
    };
    
    @include m.mixMQ(v.$movil) {
        height: 615px;
    };

    @include m.mixMQ(v.$movil414) {
        height: 528px;
    };

    @include m.mixMQ(v.$movilSM) {
        height: 480px;
    };

    .progress-bar {
        position: absolute;
        bottom: -1rem;
        left: 2px;
        z-index: 10000;
        background-color: v.$color1;
        height: 4px;
        width: 98%;
        border-radius: 10px;
        animation: grow 7s linear infinite;
        transform-origin: left;
    }

    .paquete_hardware {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        filter: brightness(0%);
        transition: all 1.25s ease-in-out;
            
        img {
            border-radius: 10px;
            box-shadow: v.$box-shadow2;
        }
    }

    .banner_visible {
        opacity: 1;
        filter: brightness(100%);
    }
    
}


